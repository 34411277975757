import React, { Component } from 'react'

export class BuildingManagerChat extends Component {
  render() {
    return (
      <div class='chat-card'>
        <h1>Username: John Doe</h1>
            <div class="chat-box">
                <div><span class="username">Alice:</span><span class="message">Hi, how are you?</span></div>
                <div><span class="username">John:</span><span class="message">I'm good, thanks. How about you?</span></div>
                <div><span class="username">Alice:</span><span class="message">I'm good too.</span></div>
                <div><span class="username">Bob:</span><span class="message">Hello everyone!</span></div>
                <div><span class="username">John:</span><span class="message">Hey Bob!</span></div>
                <div><span class="username">Alice:</span><span class="message">Hi Bob!</span></div>
            </div>
            <input type="text" placeholder="Type your message here..."/>
            <button type="submit" class='chat-button'>Send</button>
        </div>
    )
  }
}

export default BuildingManagerChat
