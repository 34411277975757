import MainNavigation from "../components/NavigationBar/MainNavigation";
import styleAbout from "../css/About.module.css";
function AboutPage() {
  return (
    <div>
      <div>
        <MainNavigation />
        <h2>Our Story</h2>
        <div className={styleAbout.infoCard}>
          <img
            src="https://images.pexels.com/photos/250659/pexels-photo-250659.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt="condos.jpg"
          />
          <p>
            At ABC Property Management, we believe that successful property
            management starts with a commitment to excellence. With over 20
            years of experience in the industry, we've built a reputation for
            delivering high-quality service and results that exceed our clients'
            expectations.We're proud to offer a range of exceptional facilities
            for our clients. Whether you're a property owner or a tenant, you
            can count on us to provide you with the best possible amenities and
            services to help you feel right at home. Our portfolio includes a
            variety of high-quality buildings, ranging from cozy apartments to
            spacious commercial properties. Each of our buildings is expertly
            maintained by our skilled maintenance staff, ensuring that you have
            a comfortable and safe environment to work or live in. We understand
            the importance of relaxation and recreation, which is why many of
            our properties feature swimming pools that are perfect for unwinding
            and staying active. Our pools are regularly cleaned and maintained
            to ensure that they're always in top condition for our clients to
            enjoy. Our properties are surrounded by beautifully landscaped
            gardens that provide a peaceful and relaxing environment for our
            clients. We take great pride in maintaining our gardens to the
            highest standards, ensuring that they're always in bloom and looking
            their best.
          </p>
          <p>
            At ABC Property Management, we take the safety and security of our
            clients very seriously. That's why many of our properties are
            staffed with security guards who are trained to handle any situation
            that may arise. You can rest easy knowing that you're in good hands
            with our security team. Our skilled maintenance staff is available
            24/7 to handle any maintenance issues that may arise at your
            property. We understand the importance of prompt and efficient
            service, which is why we work quickly to resolve any issues and
            ensure that your property is always in top condition.
          </p>
          <p>
            Our team of licensed and certified professionals includes property
            managers, leasing agents, maintenance technicians, and
            administrative staff, all of whom are dedicated to providing the
            highest level of service to our clients. We work closely with
            property owners to help them achieve their investment goals, and we
            pride ourselves on our ability to deliver personalized service and
            customized solutions that meet the unique needs of each client.
          </p>
        </div>
        <h2>Our Team</h2>
        <div className={styleAbout.infoCard}>
          <img src="../images/male-sit.jpg" alt="condos.jpg" />
          <p>
            <strong>John Smith - CEO</strong>
            <br />
            John Smith, our CEO, is a visionary leader with a wealth of
            experience in the industry. With his leadership, our company has
            achieved remarkable success and growth. John is a natural
            communicator who inspires and motivates his team to strive for
            excellence. He has a passion for innovation and is always exploring
            new ways to drive the company forward. Before joining our company,
            John served as the CEO of a leading tech company where he
            transformed the business into a market leader. His experience and
            insights have been instrumental in driving our company's success. He
            is dedicated to creating a positive workplace culture and values
            collaboration, creativity, and hard work. John's leadership style is
            rooted in transparency and inclusivity. He believes in empowering
            his team to take ownership of their roles and make a meaningful
            impact. He is committed to creating an environment where everyone
            feels valued, respected, and heard. As the CEO, John's vision for
            the future is to continue expanding our company's reach and impact.
            He believes that innovation and technology can solve many of the
            world's problems, and he is dedicated to making a positive impact
            through our products and services
          </p>
        </div>
        <div className={styleAbout.infoCard}>
          <img src="../images/potrait.jpg" alt="condos.jpg" />
          <p>
            <strong>Jane Doe - CTO</strong>
            <br />
            Jane Doe, our CTO, is a visionary technologist who is passionate
            about driving innovation. She brings a wealth of experience and
            expertise to her role, and her contributions have been instrumental
            in our company's success. Jane is dedicated to exploring new
            technologies and finding ways to use them to solve complex problems.
            Before joining our company, Jane held various technical leadership
            roles at top tech companies where she developed a reputation as a
            creative problem-solver and a thought leader. Her technical
            expertise and strategic thinking have been instrumental in driving
            our company's growth. Jane is dedicated to creating a culture of
            innovation and excellence. She is a natural leader who inspires and
            motivates her team to take ownership of their work and strive for
            excellence. She values collaboration, creativity, and open
            communication, and believes that the best ideas come from diverse
            perspectives. As our CTO, Jane's vision for the future is to
            continue pushing the boundaries of technology and exploring new ways
            to make a positive impact on the world. She believes that technology
            can be a powerful force for good, and she is dedicated to using it
            to solve some of the world's most pressing challenges.
          </p>
        </div>
        <div className={styleAbout.cfooter}>
          <p>&copy; 2023 Terrazas de guacuco, Inc.</p>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
