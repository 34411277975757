import React, { useEffect, useState } from "react";
import styles from "./ProfilePage.module.css";
import FormInput from "../layout/FormInput";
import {
  getVisitorDetails,
  updateVisitor,
} from "../../apis/apiClient";
import Loader from "../spinning-loader/loader";

const ProfilePage = () => {
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [values, setValues] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    vehicle_plate: "",
    dl_number: "",
  });
  useEffect(() => {
    const visitor_id = localStorage.getItem("visitor_id");
    getVisitorDetails(visitor_id)
      .then((response) => {
        localStorage.setItem("v_id",response.data[0]["visitor_id"]);
        setValues(response.data[0]);
        setDataLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const inputs = [
    {
      id: 0,
      name: "first_name",
      type: "text",
      placeholder: "",
      errorMessage: "",
      label: "FirstName",
      required: false,
    },
    {
      id: 1,
      name: "last_name",
      type: "text",
      placeholder: "",
      errorMessage: "Last Name field should be valid",
      label: "LastName",
      required: true,
    },
    {
      id: 2,
      name: "email",
      type: "email",
      placeholder: "",
      errorMessage: "It should be a valid email address!",
      label: "Email",
      required: true,
    },
    {
      id: 3,
      name: "phone_number",
      type: "text",
      placeholder: "",
      errorMessage:
        "Please enter a valid phone number.It should have 10 digits",
      label: "Phone Number",
      pattern: "[0-9]{10}",
      required: true,
    },
    {
      id: 4,
      name: "vehicle_plate",
      type: "text",
      placeholder: "",
      errorMessage: "",
      label: "vehicle plate",
      required: false,
    },
    {
      id: 5,
      name: "dl_number",
      type: "text",
      placeholder: "",
      errorMessage: "",
      label: "DL Number",
      required: false,
    },
  ];

  const handleUpdateClick = (e) => {
    e.preventDefault();
    const request = {
      ...values,
      visitor_id: localStorage.getItem("visitor_id"),
    };
    setDataLoaded(false);
    updateVisitor(request)
      .then((response) => {
        setValues(response.data[0]);
        setDataLoaded(true);
      })
      .catch((err) => {
        console.error(err);
        alert(err.response.data.errorMessage);
      });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  if (!isDataLoaded) {
    return <Loader />;
  }

  return (
    <div className={styles.profilePageContainer}>
      <div className={styles.profileInfoContainer}>
        <img
          src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
          alt="Profile Picture"
          className={styles.profilePicture}
        />
        <h1 className={styles.profileName}>
          {values["first_name"] ? values["first_name"].toLocaleUpperCase() : ""}{" "}
          {values["last_name"] ? values["last_name"].toLocaleUpperCase() : ""}
        </h1>
        <form onSubmit={handleUpdateClick}>
          <div className={styles.row}>
            <div className={styles.column}>
              <FormInput
                key={inputs[0].id}
                {...inputs[0]}
                value={values[inputs[0].name]}
                onChange={handleChange}
              />
            </div>
            <div className={styles.column}>
              <FormInput
                key={inputs[1].id}
                {...inputs[1]}
                value={values[inputs[1].name]}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.column}>
              <FormInput
                key={inputs[2].id}
                {...inputs[2]}
                value={values[inputs[2].name]}
                onChange={handleChange}
              />
            </div>
            <div className={styles.column}>
              <FormInput
                key={inputs[3].id}
                {...inputs[3]}
                value={values[inputs[3].name]}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.column}>
              <FormInput
                key={inputs[4].id}
                {...inputs[4]}
                value={values[inputs[4].name]}
                onChange={handleChange}
              />
            </div>
            <div className={styles.column}>
              <FormInput
                key={inputs[5].id}
                {...inputs[5]}
                value={values[inputs[5].name]}
                onChange={handleChange}
              />
            </div>
          </div>
          <button className={styles.btnPrimary} onClick={handleUpdateClick}>
            Update
          </button>
        </form>
      </div>
    </div>
  );
};

export default ProfilePage;
