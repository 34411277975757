import React from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
//Don't remove this import
import Chart from "chart.js/auto";
import { Pie } from "react-chartjs-2";

function PieChart(props) {
  ChartJS.register(ArcElement, Tooltip, Legend);
  return (
    <div>
      <Pie data={props.data} />
    </div>
  );
}

export default PieChart;
