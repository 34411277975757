import React from "react";
import { useState,useEffect } from "react";
import ReadOnlyTable from "./ReadOnlyTable";
import Loader from "../spinning-loader/loader";
import { getAllResidents, updateResident } from "../../apis/apiClient";

const ResidentEntries = (props) => {

  const [data, setData] = useState([]);

  useEffect(() => {
    getAllResidents()
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleGrantAccess = (row, isGranted)=>{
    let request = {
      "resident_id": row.resident_id,
      "userId": localStorage.getItem("userId"),
      "access_granted":isGranted
    }
    updateResident(request).then(response=>{
      const index = data.findIndex((emp) => emp.resident_id === row.resident_id);
      const temp = [...data]
      console.log(response)
      temp[index]['access_granted'] =response.data[0].access_granted;
      setData(temp);
    }).catch(err => {
      console.error(err);
      alert(err.response.data.errorMessage);
    });
  };

  const handleRevokeAccess = (row, isRevoked)=>{
    let request = {
      "resident_id": row.resident_id,
      "userId": localStorage.getItem("userId"),
      "access_granted":isRevoked
    }
    updateResident(request).then(response=>{
      const index = data.findIndex((emp) => emp.resident_id === row.resident_id);
      const temp = [...data]
      temp[index]['access_granted'] =response.data[0].access_granted;
      setData(temp);
    }).catch(err => {
      console.error(err);
      alert(err.response.data.errorMessage);
    });
  };

  if (!data.length){
    return <Loader/>
   } 

  return (
    <div>
        <ReadOnlyTable
          data={data}
          handleGrantAccess={handleGrantAccess}
          handleRevokeAccess={handleRevokeAccess}
          view = "resident"
        />
    </div>
  );
};

export default ResidentEntries;
