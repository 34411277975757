import { useState } from "react";
import Button from "../components/layout/Button";
import FormInputv2 from "../components/layout/FormInputv2";
import TextArea from "../components/layout/TextArea";
import MainNavigation from "../components/NavigationBar/MainNavigation";
import stylesLogin from "../css/ContactUs.module.css";
import { contactUs } from "../apis/apiClient";

function ContactUsPage() {
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const inputs = [
    {
      id: 0,
      name: "firstName",
      type: "text",
      placeholder: "",
      errorMessage: "",
      label: "FirstName",
      required: false,
    },
    {
      id: 1,
      name: "lastName",
      type: "text",
      placeholder: "",
      errorMessage: "Last Name field should be valid",
      label: "LastName",
      required: true,
    },
    {
      id: 2,
      name: "email",
      type: "email",
      placeholder: "",
      errorMessage: "It should be a valid email address!",
      label: "Email",
      required: true,
    },
  ];

  const message = {
    name: "message",
    placeholder: "",
    errorMessage: "Message should be valid and not empty.",
    label: "Message",
    cols: "30",
    rows: "3",
    required: true,
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const request = {
      first_name : values["firstName"],
      last_name : values["lastName"],
      email : values["email"],
      message: values["message"]
    }
    contactUs(request).then((response)=>{
      alert(response.successMessage);
      let clearVals = {
        firstName: "",
        lastName: "",
        email: "",
        message: ""
      };
      setValues({...clearVals});
    }).catch((err) => {
      console.error(err);
      alert(err.response.errorMessage);
    });
  };
  return (
    <div>
      <MainNavigation />
      <div className={stylesLogin.pjwrapper}>
        <div className={stylesLogin.signUp}>
          <h2>Contact Us</h2>
          <form onSubmit={handleSubmit}>
            {inputs.map((input) => (
              <FormInputv2
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}
            <TextArea {...message} onChange={onChange} />
            <Button name="btnLogin" value="Submit" />
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUsPage;
