import React, { Component } from 'react'

export class TableHeader extends Component {
ln=this.props.lenght
  render() {
    const arr=[]
    for (const key in this.props) {
      if (key!=='heading')
      {
        arr.push(this.props[key])
      }}    
      return (
        <thead>
        <tr>
          <th>{this.props.heading}</th>
        </tr>
        <tr>
        {arr.map(ar => (
        <th>{ar}</th>
         ))}
        </tr>
        </thead>
    )
  }
}

export default TableHeader
