import { Component } from 'react';
import BuildingManagerNavigationPane from './BuildingManagerNavigation';
class BuildingManagerVisitors extends Component{
    render(){     
        return(
            <div>
                    <BuildingManagerNavigationPane /> 
                    <div>
                        Visitors to come tomorrow.
                            <table className='tabletop'>
                                <thead style={{backgroundColor: '#f8f8f8'}}>
                                <tr>
                                    <th className='tablehead'>ID</th>
                                    <th className='tablehead'>FirstName</th>
                                    <th className='tablehead'>LastName</th>
                                    <th className='tablehead'>TimeSlot</th>
                                    <th className='tablehead'>Building</th>
                                    <th className='tablehead'>Apartment</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className='tablecol'>1</td>
                                    <td className='tablecol'>VisitorFirstname</td>
                                    <td className='tablecol'>VisitorLastname</td>
                                    <td className='tablecol'>9:00AM-10:00AM</td>
                                    <td className='tablecol'>A</td>
                                    <td className='tablecol'>100</td>
                                </tr>
                                <tr>
                                    <td className='tablecol'>2</td>
                                    <td className='tablecol'>VisitorFirstname</td>
                                    <td className='tablecol'>VisitorLastname</td>
                                    <td className='tablecol'>9:00AM-10:00AM</td>
                                    <td className='tablecol'>B</td>
                                    <td className='tablecol'>100</td>
                                </tr>
                                <tr>
                                    <td className='tablecol'>3</td>
                                    <td className='tablecol'>VisitorFirstname</td>
                                    <td className='tablecol'>VisitorLastname</td>
                                    <td className='tablecol'>9:00AM-10:00AM</td>
                                    <td className='tablecol'>C</td>
                                    <td className='tablecol'>100</td>
                                </tr>
                                <tr>
                                    <td className='tablecol'>4</td>
                                    <td className='tablecol'>VisitorFirstname</td>
                                    <td className='tablecol'>VisitorLastname</td>
                                    <td className='tablecol'>9:00AM-10:00AM</td>
                                    <td className='tablecol'>A</td>
                                    <td className='tablecol'>100</td>
                                </tr>
                                </tbody>
                            </table>

                    </div>
            </div>
        )
    }
}
export default BuildingManagerVisitors;
