import React, { Component } from 'react'
import TableHeader from './TableHeader'
import UserDashBoardTableRows from './UserDashBoardTableRows'

export class ClubMembership extends Component {
  constructor(props)
  {
      super(props)
      this.state={
          items: [],
          Pool:'',
          Garden:'',
      }
  }
  componentDidMount(){
    fetch(
      "http://terrazasapp.dxj0015.uta.cloud/terrazas-de-gucaco-backend/Resident.php/membership",
      {
          method:'POST', 
          body:JSON.stringify({'Email':'a@gmail.com'})
      }
      )
      .then(
      (res) =>res.json())
      .then((json) =>{
          this.setState({
              // items: json,
              DataisLoaded: true,
              Pool:json["Pool"],
              Garden:json["Garden"],
          })
  })

  }
  render() {
    const {items}=this.state
    // console.log(this.state.Pool)
    // console.log(this.state.Garden)
    // console.log(this.state.items)

    const chngPool = (event)=>{
        this.setState({
            Pool:event.target.value
        })
    }

    const chngGarden =(event)=>{
      this.setState({
        Garden:event.target.value
    })
    }
    return (
        <table className='activity-table'>
            <TableHeader heading='Join Club' col1='Club name' col2='membership' col3='submit'/>
            <UserDashBoardTableRows val1='Pool' val2={<tr><input type={'checkbox'} name='poolMembership' value='pool' checked={this.state.Pool} onChange={chngPool}/></tr> } val3={<button>submit</button>}/>
            <UserDashBoardTableRows val1='Garden' val2={<tr><input type={'checkbox'} name='gardenMembership' value='garden' checked={this.state.Garden} onChange={chngGarden}/></tr> } val3={<button>submit</button>}/>
        </table>
    )
  }
}

export default ClubMembership
