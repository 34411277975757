import React, { useState, useEffect } from "react";
import styles from "./GMap.module.css";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
  DirectionsService
} from "@react-google-maps/api";
import Loader from "../spinning-loader/loader";

const libraries = ["places"];

const GMap = (props) => {
  const source = { lat: 32.7299, lng: -97.115 };
  const dest = { lat: 32.7791, lng: -96.8075 };
  useEffect(() => {
    const cachedDirections = localStorage.getItem('directions');
    if (cachedDirections) {
      setIsDirectionsLoaded(true);
      setDirections(JSON.parse(cachedDirections));
    }else{
      setIsDirectionsLoaded(false);
    }
  }, []);
  const [directions, setDirections] = useState(null);
  const [isDirectionsLoaded, setIsDirectionsLoaded] = useState(false); 

  const mapContainerStyle = {
    height: "100vh",
    width: "100vw",
  };
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GMAP_API_KEY,
    libraries: libraries,
  });

  const [map, setMap] = useState(/** @type google.maps.Map*/ (null));
  if (!isLoaded) {
    return <Loader />;
  }


  const directionsServiceOptions = {
    origin: source, 
    destination: dest, 
    travelMode: 'DRIVING'
  };

  const handleDirectionsResult = (result) => {
    if (result) {
      localStorage.setItem('directions', JSON.stringify(result));
      setDirections(result); 
      setIsDirectionsLoaded(true);
    }
  };
  console.log("called")
  return (
    <div className={styles.mapsForm}>
      <GoogleMap
        center={source}
        zoom={15}
        mapContainerStyle={mapContainerStyle}
        options={{
          zoomControl: false,
          streetViewControl:false,
          mapTypeControl:false,
          fullscreenControl:false
        }}
        onLoad={(map) => setMap(map)}
      >
        <Marker position={source} />
        {directions && (
          <DirectionsRenderer
            options={{ directions: directions }}
          />
        )}
        {!isDirectionsLoaded && (<DirectionsService
          options={directionsServiceOptions}
          callback={handleDirectionsResult}
        />)}
        </GoogleMap>
        {/*<Autocomplete>
          <input type="text"placeholder="Enter current location"/>
      </Autocomplete> */}

    </div>
  );
};

export default GMap;
