import './PoolManager.css'
import { useState,useEffect } from 'react';
import PoolManagerNavigationPane from './PoolManagerNavigationPane';
import axios from "axios";
import Loader from '../spinning-loader/loader';


const baseClient = axios.create({
    baseURL: "https://terrazasapp.dxj0015.uta.cloud/terrazas-de-gucaco-backend/",
  });
  
  export const getAllPoolTimings = async () => {
    const response = await baseClient.get("/Pool.php/timings");
    return response.data;
  };

  export const addPoolTiming = async (request) => {
    const response = await baseClient.post("/Pool.php/timings",request);
    return response.data;
  };

const PoolManager = (props) => {
    
    const [items, setItems] = useState([]);
    const [day, setDay] = useState('Monday');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
  
    useEffect(() => {
        getAllPoolTimings()
      .then((response) => {
        console.log(response);
        setItems(response)
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
    }, []);
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      console.log(day);
      let request = {
        day: day,
        StartTime: startTime,
        EndTime: endTime
      }
      addPoolTiming(request)
      .then((response) => {
        console.log(items)
        let index = items.findIndex((item) => item.Day == request.day);
        if(index){
            items[index].StartTime = startTime;
            items[index].EndTime = endTime
        }
        setItems([...items]);
      })
      .catch((err) => {
        console.log(err);
      });
    }

    
  function onStartTimeChange(event) {
    setStartTime(event.target.value);
  }
  function onEndTimeChange(event) {
    setEndTime(event.target.value);
  }
  function onDayChange(event) {
    setDay(event.target.value);
  }
  if(!isLoaded){
    return <Loader/>
  }

    return(
        <div>
              <PoolManagerNavigationPane />
            <div>
            <table className='tabletop'>
        <thead style={{backgroundColor: '#f8f8f8'}}>
            <tr>
                <th className = 'tablehead'>Day</th>
                <th className = 'tablehead'>Start Time</th>
                <th className = 'tablehead'>End Time</th>
            </tr>
            </thead>
            {
                items.map((item) => ( 
                <tr key = { item.day } >
                    <td className='tablecol'>{ item.Day }</td>
                    <td className = 'tablecol'>{ item.StartTime }</td>
                    <td className = 'tablecol'>{ item.EndTime }</td> 
                </tr>
                ))
            }
            </table>
        </div>  
                <div style={{marginTop: '10px'}}>
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="days"className='label1'>Day:</label>
                            <select name="days" className='days'  onChange={onDayChange}>
                            <option value="Monday">Monday</option>
                            <option value="Tuesday">Tuesday</option>
                            <option value="Wednesday">Wednesday</option>
                            <option value="Thursday">Thursday</option>
                            <option value="Friday">Friday</option>
                            <option value="Saturday">Saturday</option>
                            <option value="Sunday">Sunday</option>
                            </select>
                            <label htmlFor="StartTime" style={{display: 'block', marginBottom: '10px'}}>Start Time:</label>
                            <input type="text" id="StartTime" name="StartTime" style={{padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid black', solid: '#ccc', marginBottom: '20px'}} onChange={onStartTimeChange}></input>
                            <label htmlFor="EndTime" style={{display: 'block', marginBottom: '10px'}}>End Time:</label>
                            <input type="text" id="EndTime" name="EndTime" style={{padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid black', solid: '#ccc', marginBottom: '20px'}} onChange={onEndTimeChange}></input>
                            <input type="submit" value="Submit" style={{backgroundColor: '#4CAF50', color: 'white', padding: '10px 20px', borderRadius: '5px', border: 'none', cursor: 'pointer'}}></input>
                        </form>
                </div>
                
        </div>
)
}

export default PoolManager;