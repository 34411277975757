import React from 'react'
import VisitorNavigationBar from './VisitorNavigationBar'
import '../Visitor/Visitor.css'
import TableHeader from './TableHeader'
import TableRows from './TableRows'

export default function VisitorDashBoard() {
  return (
    <div>
    <VisitorNavigationBar/>
      
    <table class='Visitor-table'>
      <TableHeader val1='Entery'	val2='Exit'	val3='VL number' 	val4='Date'	val5='visited block'/>
      <TableRows val1='13:01' val2='15:00' val3='GJ01MY7898' val4='12/02/2019' val5='Block-A'/> 
      <TableRows val1='09:01' val2='15:00' val3='GJ01MY7898' val4='11/02/2015' val5=' Block-B'/>
      <TableRows val1='17:01' val2='21:00' val3='GJ01MY7898' val4='01/02/2019' val5=' Block-A'/>
    </table>
  </div>
  )
}
