import React, { useState } from 'react';

function ScheduleGenerator() {
  const [numPeople, setNumPeople] = useState(0);
  const [schedule, setSchedule] = useState([]);

  const generateSchedule = () => {
    const newSchedule = [];
    for (let i = 1; i <= numPeople; i++) {
      newSchedule.push(
        { name: `Person ${i}`, monday: '8:00am - 10:00am', tuesday: '10:00am - 12:00pm', wednesday: '1:00pm - 3:00pm', thursday: '3:00pm - 5:00pm', friday: '5:00pm - 7:00pm' }
      );
    }
    setSchedule(newSchedule);
  };

  const tableStyle = {
    borderCollapse: 'collapse',
    marginTop: '20px',
    fontSize: '16px',
    width: '100%',
  };

  const thStyle = {
    backgroundColor: '#ddd',
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
  };

  const tdStyle = {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
  };

  return (
    <div>
      <label>
        Number of People:
        <input type="number" value={numPeople} onChange={(e) => setNumPeople(parseInt(e.target.value))} />
      </label>
      <button onClick={generateSchedule}>Generate Schedule</button>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={thStyle}>Name</th>
            <th style={thStyle}>Monday</th>
            <th style={thStyle}>Tuesday</th>
            <th style={thStyle}>Wednesday</th>
            <th style={thStyle}>Thursday</th>
            <th style={thStyle}>Friday</th>
          </tr>
        </thead>
        <tbody>
          {schedule.map((item, index) => (
            <tr key={index}>
              <td style={tdStyle}>{item.name}</td>
              <td style={tdStyle}>{item.monday}</td>
              <td style={tdStyle}>{item.tuesday}</td>
              <td style={tdStyle}>{item.wednesday}</td>
              <td style={tdStyle}>{item.thursday}</td>
              <td style={tdStyle}>{item.friday}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ScheduleGenerator;