import React, { Component } from 'react'
import '../../css/UserDashBoard.css'
import VisitorNavigationBar from './VisitorNavigationBar'

export class VisitorVehicleAdd extends Component {
    constructor()
    {
        super()
        this.state={
            carNumberPlate:'',
            carName:'',
            carMaker:'',
            owner:'',
            ownerAptNumber:'',
        }
    }
    chngCarNumberPlateHandler=(event)=>{
        this.setState({
            carNumberPlate:event.target.value
        })
    }
    chngCarNameHandler=(event)=>{
        this.setState({
            carName:event.target.value
        })
    }

    chngCarMakerHandler=(event)=>{
        this.setState({
            carMaker:event.target.value
        })
    }

    chngOwnerNameHandler=(event)=>{
        this.setState({
            owner:event.target.value
        })
    }

    chngOwnerAptHandler=(event)=>{
        this.setState({
            ownerAptNumber:event.target.value
        })
    }

        // handleSumbit=(event)=>{
        //     alert(`${this.state.ownerAptNumber},${this.state.owner},
        //     ${this.state.carName},${this.state.carMaker},${this.state.carNumberPlate}`)
        //     event.preventDefault()
        // }

  render() {
    return (
        <div>
      <VisitorNavigationBar />
      <div class='vehicle-card'>
        <h1>Manage your  Vehicles!!</h1>
                <div class="container-vehicle">
                <form action="#" >
                    <div>
                    Car number plate<div class="row">
                            <div class="col">
                            <input type="text" id="addr" name="Car number plate" placeholder="Car number plate" onChange={this.chngCarNumberPlateHandler}/>
                            </div>
                        </div>
                        
                    <div class="vehicle-form-group">
                        Car name<div class="row">
                            <div class="col">
                                <input  type="text" id="amount" name="amount" placeholder="Car name" onChange={this.chngCarNameHandler}/>
                            </div>
                        </div>
                    </div>
                    <div class="vehicle-form-group">
                        Car maker<div class="row">
                            <div class="col">
                                <input type="text" id="amount" name="amount" placeholder="Car maker" onChange={this.chngCarMakerHandler}/>
                            </div>
                        </div>
                    </div>
                    <div class="vehicle-form-group">
                        owner name
                        <div class="row">
                            <div class="col">
                                <input type="text" id="amount" name="amount" placeholder="owner name" onChange={this.chngOwnerNameHandler}/>
                            </div>
                        </div>
                    </div>

                    <div class="vehicle-form-group">
                        owner apt number
                        <div class="row">
                            <div class="col">
                                <input type="text" id="amount" name="owner apt number" placeholder="owner apt number" onChange={this.chngOwnerNameHandler}/>
                            </div>
                        </div>
                    </div>
                    <button class="signup-btn" type="submit">edit details</button>
                  <button class="signup-btn" type="submit">Add more vehicles</button>                  
                </div>
                </form>
            </div>                
      </div>
      </div>
    )
  }
}

export default VisitorVehicleAdd