import React from 'react'
import TableHeader from './TableHeader'
import TableRows from './TableRows'
import '../Visitor/Visitor.css'
import VisitorNavigationBar from './VisitorNavigationBar'

export default function VisitorMaps() {
  return (
    <div>
    <VisitorNavigationBar/>
      <div class="visitor-card">
        <img class='images-style' src="../images/map.jpeg" />
	    	<table class='visitor-table'>
            <TableHeader heading='Directions' val1='Srno.' valu2='directions'/>
            <TableRows val1='1' val2='Go staright'/>
            <TableRows val1='2' val2='take a right and go staright'/>
            <TableRows val1='3' val2='take a left and go staright'/> 
            <TableRows val1='4' val2='take a right and go staright you will reach your destination in 4 00 meters'/> 
        </table>
    </div>
    </div>
  );
}
