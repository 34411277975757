import './PoolReport.css'
import { Component } from 'react';
import PoolManagerNavigationPane from './PoolManagerNavigationPane';
class PoolReport extends Component{
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            Id:'',
            FirstName:'',
            LastName:'',
            TimeSlot:'',
            Pool:'',	
            DataisLoaded: false
        };
        this.handleSubmit=this.handleSubmit
    }
    componentDidMount() {
        fetch(
            "http://terrazasapp.dxj0015.uta.cloud/terrazas-de-gucaco-backend/Pool.php/report")
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    items: json,
                    DataisLoaded: true
                })
        })
    };
    render(){  
        const handleSubmit = async (event) => {
            // event.preventDefault()
            console.log('hello',this.state.Id)
            const response = await fetch('http://terrazasapp.dxj0015.uta.cloud/terrazas-de-gucaco-backend/Pool.php/report',{
                method:'DELETE',

                body:JSON.stringify({'Id':this.state.Id,'FirstName':this.state.FirstName,'LastName':this.state.LastName,'TimeSlot':this.state.TimeSlot,'Pool':this.state.Pool})
            });
          }

        const setId=(event)=>{
            this.setState({Id:event.target.value})
        }

        const setFirstName=(event)=>{
            this.setState({StartTime:event.target.value})
        }

        const setLastName=(event)=>
        {this.setState({EndTime:event.target.value})
        }
        const setTimeSlot=(event)=>{
        this.setState({setTimeSlot:event.target.value})
        }
        
        const { DataisLoaded, items } = this.state;
           
        return(
            <div>
                    <PoolManagerNavigationPane /> 
                    <div>
                        Visitors to come tomorrow.
                        <table className='tabletop'>
                        <thead style={{backgroundColor: '#f8f8f8'}}>
                            <tr>
                                <th className = 'tablehead'>Id</th>
                                <th className = 'tablehead'>FirstName</th>
                                <th className = 'tablehead'>LastName</th>
                                <th className = 'tablehead'>EnterTime</th>
                                <th className = 'tablehead'>ExitTime</th>
                                <th className = 'tablehead'>Pool</th>
                            </tr>
                            </thead>
                            {
                        items.map((item) => ( 
                        <tr key = { item.day } >
                            <td className='tablecol'>{ item.LogId }</td>
                            <td className = 'tablecol'>{ item.FirstName }</td>
                            <td className = 'tablecol'>{ item.LastName }</td>
                            <td className = 'tablecol'>{ item.EnterTime }</td>
                            <td className = 'tablecol'>{ item.ExitTime }</td>
                            <td className = 'tablecol'>{ item.PoolNumber }</td>
                        </tr>
                        ))
                    }
                    </table>
                </div>    
            </div>  
        )
    }
}
export default PoolReport;
