import React, { Component } from 'react'
import '../../css/UserDashBoard.css'
import DashBoardNavigation from './DashBoardNavigation'
import { updateResident } from '../../apis/apiClient'

export class UserProfile extends Component {
        constructor(props)
        {
            super(props)
            this.state={
                items: [],
                FirstName:'adit2',
                LastName:'',
                Email:'',
                Phone:'',
                UnitNumber:'',
                BuildingId:'',
                DOB:''
            }
        }
        componentDidMount() {
            fetch(
                "http://terrazasapp.dxj0015.uta.cloud/terrazas-de-gucaco-backend/Resident.php/profile",
                {
                    method:'POST', 
                    body:JSON.stringify({'Email':localStorage.getItem('userId')})
                }
                )
                .then(
                (res) =>res.json())
                .then((json) =>{
                    this.setState({
                        items: json,
                        FirstName:json["first_name"],
                        LastName:json["last_name"],
                        Email:json["email"],
                        Phone:json["phone"],
                        UnitNumber:json["unit_number"],
                        BuildingId:json["building_id"],
                        DataisLoaded: true
                    })
            })
        };
      render() 
      {

        function formatDate(dateString) {
            if(dateString){
              const [year, month, day] = dateString.split("-");
              return `${month}/${day}/${year}`;
            }
            return "";
          }

        const handleUpdate = (e)=>{
            e.preventDefault();
            let request = {first_name : this.state.FirstName,
                last_name: this.state.LastName,
                email:this.state.Email,
                phone:this.state.Phone,
                building_id:this.state.BuildingId,
                unit_number:this.state.UnitNumber,
                userId:localStorage.getItem("userId")}
                console.log(request)
        
            updateResident(request).then(response=>{
              this.componentDidMount();
            }).catch(err => {
              console.error(err);
              alert(err.response.data.errorMessage);
            });
        }
        const chngFirstName=(event)=>{
            this.setState({
                FirstName:event.target.value
            })
        }
        const chngLastName=(event)=>{
            this.setState({
                LastName:event.target.value
            })
        }
    
        const chngEmail=(event)=>{
            this.setState({
                Email:event.target.value
            })
        }
    
        const chngPhone=(event)=>{
            this.setState({
                Phone:event.target.value
            })
        }
    
        const chngUnitNumber=(event)=>{
            this.setState({
                UnitNumber:event.target.value
            })
        }
        const chngBuildingId=(event)=>{
            this.setState({
                BuildingId:event.target.value
            })
        }
        
        const chngDOB=(event)=>{
            this.setState({
                DOB:event.target.value
            })
        }
        const { DataisLoaded, items } = this.state; 
        console.log('items',items)

        return (
            <div>
          <DashBoardNavigation/>
          <div class='vehicle-card'>

            <h1>Manage your  Profile!!</h1>
                    <div class="container-vehicle">
                    <form  onSubmit={handleUpdate}>
                        <div>
                        FirstName<div class="row">
                                <div class="col">
                                <input type="text" id="addr" name="FirstName" placeholder="FirstName" value={this.state.FirstName} onChange={chngFirstName} />
                                </div>
                            </div>
                            
                        <div class="vehicle-form-group">
                        LastName<div class="row">
                                <div class="col">
                                    <input  type="text" id="amount" name="LastName" placeholder="LastName" value={this.state.LastName} onChange={chngLastName}/>
                                </div>
                            </div>
                        </div>
                        <div class="vehicle-form-group">
                        Email<div class="row">
                                <div class="col">
                                    <input type="text" id="amount" name="Email" placeholder="Car maker" value={this.state.Email} onChange={chngEmail}/>
                                </div>
                            </div>
                        </div>
                        <div class="vehicle-form-group">
                        Phone
                            <div class="row">
                                <div class="col">
                                    <input type="text" id="amount" name="Phone" placeholder="owner name" value={this.state.Phone} onChange={chngPhone}/>
                                </div>
                            </div>
                        </div>
                        <div class="vehicle-form-group">
                        BuildingId
                            <div class="row">
                                <div class="col">
                                    <input type="text" id="amount" name="BuildingId" placeholder="owner apt number" value={this.state.BuildingId} onChange={chngBuildingId}/>
                                </div>
                            </div>
                        </div>
                        <div class="vehicle-form-group">
                        UnitNumber
                            <div class="row">
                                <div class="col">
                                    <input type="text" id="amount" name="UnitNumber" placeholder="owner apt number" value={this.state.UnitNumber} onChange={chngUnitNumber}/>
                                </div>
                            </div>
                        </div>
                        <button class="signup-btn" type="submit" onSubmit={handleUpdate}>edit details</button>
                    </div>
                    </form>
                </div>                
          </div>
          </div>
        // 'hello'
        )
    }
}    
export default UserProfile
