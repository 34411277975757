import { Route, Routes } from "react-router";
import Navigation from "./Navigation";
import styles from './VisitorManager.module.css';
import VisitorTimings from "./VisitorTimings";
import ProfilePage from "./ProfilePage";
import GMap from "./GMap";
import EventRegistration from "./EventRegistration";
import Chat from "../Chat/Chat";

const VisitorManager = () => {
  return (
    <div className={styles.container}>
      <Navigation />
      <Routes>
        <Route path="profile" element={<ProfilePage />} />
        <Route path="directions" element={<GMap/>} />
        <Route path="timings" element={<VisitorTimings />} />
        <Route path="events" element={<EventRegistration />} />
        <Route path="chat" element={<Chat/>} />
      </Routes>
    </div>
  );
};
export default VisitorManager;
