import { Route, Routes } from "react-router";
import Navigation from "./Navigation";
import ResidentEntries from "./ResidentEntries";
import SecurityDetails from "./SecurityDetails";
import styles from './SecurityDetails.module.css'
import SecurityTimings from "./SecurityTimings";
import VisitorEntries from "./VisitorEntries";
import DashBoard from "./DashBoard";
import Report from "../report/Report";
import Chat from "../Chat/Chat";
import ScheduleGenerator from "./ScheduleGenerator";

const SecurityManager = () => {
  return (
    <div className={styles.container}>
      <Navigation />
      <Routes>
        <Route path="dashboard" element={<DashBoard />} />
        <Route path="manage" element={<SecurityDetails />} />
        <Route path="timings" element={<SecurityTimings />} />
        <Route path="visitor-entries" element={<VisitorEntries />} />
        <Route path="resident-entries" element={<ResidentEntries />} />
        <Route path="report" element={<Report/>} />
        <Route path="chat" element={<Chat/>} />
        <Route path="schedule" element={<ScheduleGenerator/>} />
      </Routes>
    </div>
  );
};

export default SecurityManager;
