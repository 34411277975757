import Button from "../layout/Button";
import styles from "./SecurityDetails.module.css";
import { useState, useEffect } from "react";
import Modal from "../layout/Modal";
import FormInput from "../layout/FormInput";
import ReadOnlyTable from "./ReadOnlyTable";
import { getAllSecurity,deletSecurity,createSecurity,updateSecurity } from "../../apis/apiClient";
import Loader from "../spinning-loader/loader";

const SecurityDetails = (props) => {
  //Modal Inputs
  const inputs = [
    {
      id: 1,
      name: "first_name",
      type: "Text",
      placeholder: "Enter First Name",
      errorMessage: "",
      label: "First Name",
      required: false,
    },
    {
      id: 2,
      name: "last_name",
      type: "Text",
      placeholder: "Enter Last Name",
      errorMessage: "",
      label: "Last Name",
      required: false,
    },
    {
      id: 3,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "It should be a valid email address!",
      label: "Email",
      required: true,
    },
    {
      id: 4,
      name: "phone_number",
      type: "text",
      placeholder: "Phone Number",
      errorMessage:
        "Please enter a valid phone number.It should have 10 digits",
      label: "Phone Number",
      pattern: "[0-9]{10}",
      required: true,
    },
  ];

  // Modal Related Functions
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const openAddModal = () => {
    resetAddFormdata();
    setAddModalVisible(true);
  };
  const closeAddModal = () => {
    resetAddFormdata();
    setAddModalVisible(false);
  };

  const openEditModal = () => {
    resetEditFormdata();
    setEditModalVisible(true);
  };
  const closeEditModal = () => {
    resetEditFormdata();
    setEditModalVisible(false);
  };

  const openDeleteModal = () => {
    setDeleteModalVisible(true);
  };
  const closeDeleteModal = () => {
    setEditId(null);
    setDeleteModalVisible(false);
  };

  // Form data Updates using State
  //Add Functions
  useEffect(() => {
    getAllSecurity()
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [data, setData] = useState([]);
  const [addFormData, setAddFormData] = useState({
    first_name: "",
    last_name:"",
    email: "",
    phone_number: "",
  });
  const handleAddFormChange = (e) => {
    e.preventDefault();
    const key = e.target.getAttribute("name");
    const value = e.target.value;
    const newFormData = { ...addFormData };
    newFormData[key] = value;
    setAddFormData(newFormData);
  };
  const resetAddFormdata = () => {
    setAddFormData({
    first_name: "",
    last_name:"",
    email: "",
    phone_number: "",
    });
  };
  const handleAddFormSubmit = (e) => {
    e.preventDefault();
    const newEmp = {
      first_name: addFormData.first_name,
      last_name : addFormData.last_name,
      email: addFormData.email,
      phone_number: addFormData.phone_number,
      userId: localStorage.getItem("userId")
    };
  createSecurity(newEmp).then(response=>{
      setData([...data, response.data[0]]);
    closeAddModal();
  }).catch(err=>{
    console.log(err);
    alert(err.response.data.errorMessage);
  });
  };

  //Edit Form Functions
  const [editFormData, setEditFormData] = useState({
    first_name: "",
    last_name:"",
    email: "",
    phone_number: "",
  });

  const resetEditFormdata = () => {
    setEditFormData({
      first_name: "",
      last_name:"",
      email: "",
      phone_number: "",
    });
  };

  const [editId, setEditId] = useState(null);

  const handleEditClick = (row) => {
    setEditId(row.security_id);
    const index = data.findIndex((emp) => emp.security_id === row.security_id);
    const oldData = data[index];
    openEditModal();
    setEditFormData(oldData);
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();
    const key = event.target.getAttribute("name");
    const value = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[key] = value;
    setEditFormData(newFormData);
  };

  const handleEditFormSubmit = (e) => {
    e.preventDefault();
    let request = {...editFormData, userId:localStorage.getItem("userId")}
    updateSecurity(request).then(response=>{
      const index = data.findIndex((emp) => emp.security_id === editId);
      data[index] = { ...editFormData };
      setData(data);
      setEditId(null);
      resetEditFormdata();
      closeEditModal();
    }).catch(err => {
      console.error(err);
      alert(err.response.data.errorMessage);
    });
  };

  //Delete Modal Functions

  const handleDeleteClick = (row) => {
    openDeleteModal();
    setEditId(row.security_id);
  };

  //***************** */
  const handleDeleteSubmit = () => {
    deletSecurity(editId).then(response=>{
      let records = response.data;
      setData(records => records.filter(record=>record.security_id !== editId));
    }).catch(error => {
      console.error(error);
    });
    setEditId(null);
    closeDeleteModal();
  };
 if (!data.length){
  return <Loader/>
 } 
  return (
    <div className={styles.pjwrapper}>
      <div className={styles.addBtn}>
        <Button
          value="Add Security"
          name="btnPrimary"
          handleClick={openAddModal}
        />
      </div>
      <form onSubmit={handleAddFormSubmit}>
        <ReadOnlyTable
          data={data}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
          view="security"
        />
      </form>

      {/* Add Security Modal */}
      {addModalVisible && (
        <Modal>
          <button className={styles.closeButton} onClick={closeAddModal}>
            X
          </button>
          <h2>Add Security</h2>
          <form onSubmit={handleAddFormSubmit}>
            {inputs.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={addFormData[input.name]}
                onChange={handleAddFormChange}
              />
            ))}
            <button
              className={styles.btnPrimary}
              onSubmit={handleAddFormSubmit}
            >
              Add
            </button>
          </form>
        </Modal>
      )}

      {/* Edit Security Modal */}
      {editModalVisible && (
        <Modal>
          <button className={styles.closeButton} onClick={closeEditModal}>
            X
          </button>
          <h2>Update Details</h2>
          <form onSubmit={handleEditFormSubmit}>
            {inputs.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={editFormData[input.name]}
                onChange={handleEditFormChange}
              />
            ))}
            <button
              className={styles.btnPrimary}
              onSubmit={handleEditFormSubmit}
            >
              Update
            </button>
          </form>
        </Modal>
      )}

      {/* Delete Modal Functions */}
      {deleteModalVisible && (
        <Modal>
          <button className={styles.closeButton} onClick={closeDeleteModal}>
            X
          </button>
          <h2>Delete Details</h2>
          <p>Do you want to delete the record?</p>
          <button className={styles.btnDanger} onClick={handleDeleteSubmit}>
            Yes
          </button>
          <button className={styles.btnPrimary} onClick={closeDeleteModal}>
            No
          </button>
        </Modal>
      )}
    </div>
  );
};

export default SecurityDetails;
