import React from 'react'
import { Link } from "react-router-dom";
import styles from "../BuldingMnaager/BuildingManager.css";

export default function BuildingManagerNavigation() {
  return (
    <div>
      <div className="container">
      <header className={styles.mainNav}>
        <nav>
          <ul>
          <li>
              <Link to="/">Logout</Link>
            </li>
            <li>
              <Link to="/BuildingManagerDashBoard">Building-manager-DashBoard</Link>
            </li>
            <li>
              <Link to="/BuildingManager-pool">pool-manager</Link>
            </li>           
            <li>
              <Link to="/BuildingManager-security">security-manager</Link>
            </li>
            <li>
              <Link to="/BuildingManager-garden">garden-manager</Link>
            </li>
            <li>
              <Link to="/BuildingManager-visitor">visitor</Link>
            </li>
            <li>
              <Link to="/BuildingManager-resident">resident</Link>
            </li>
            <li>
              <Link to="/BuildingManager-report">report</Link>
            </li>
            <li>
              <Link to="/BuildingManagerSelectChat">chat</Link>
            </li>
          </ul>
        </nav>
      </header>
    </div>
    </div>
  )
}
