import styles from "../../css/MainNavigation.module.css";
import { Link } from "react-router-dom";


function GardenManagerNavigationPane() {
  return (
    <div className="container">
      <header className={styles.mainNav}>
        <nav>
        <div>
            <ul>
              <li><Link to="/">LogOut</Link></li>
              <li><Link to="/GardenManager">Garden Timings</Link></li>
              <li><Link to="/GardenManagerVisitors">Visitors</Link></li>
              <li><Link to="/GardenManagerResidents">Residents</Link></li>
              <li><Link to="/GardenReport">Garden Report</Link></li>
              <li><Link to="/GardenManagerSelectChat">Chat</Link></li>
          </ul>
      </div>
        </nav>
      </header>
    </div>
  );
}

export default GardenManagerNavigationPane;
