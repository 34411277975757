import './GardenReport.css'
import { Component } from 'react';
import GardenManagerNavigationPane from './GardenManagerNavigationPane';
class GardenReport extends Component{
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            log_id:'',
            DataisLoaded: false
        };
        this.handleSubmit=this.handleSubmit
        this.handleLog_Id=this.handleLog_Id
    }
    componentDidMount()
    {
        fetch(
            "http://terrazasapp.dxj0015.uta.cloud/terrazas-de-gucaco-backend/GardenTiming.php/report")
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    items: json,
                    DataisLoaded: true
                })      
        })

    };
    render(){
        const handleSubmit =(event)=>
        {
            // event.preventDefault()
            const response = fetch('http://terrazasapp.dxj0015.uta.cloud/terrazas-de-gucaco-backend/Resident.php/log-delete',{
                method:'Post',
                body:JSON.stringify({'log_id':this.state.log_id})
            });
        }
        const { DataisLoaded, items } = this.state;   
        
        const handleLog_Id = (event)=>
        {
            this.setState({
                log_id:event.target.value
            })
        }     
        return(
            <div>
                <GardenManagerNavigationPane />
                <div>
                        <table className='tabletop'>
                        <thead style={{backgroundColor: '#f8f8f8'}}>
                            <tr>
                                <th className = 'tablehead'>log_id</th>
                                <th className = 'tablehead'>resident_id</th>
                                <th className = 'tablehead'>First name</th>
                                <th className = 'tablehead'>Last Name</th>
                                <th className = 'tablehead'>Time slot</th>
                                <th className = 'tablehead'>exit time</th>
                                <th className = 'tablehead'>garden number</th>
                            </tr>
                            </thead>
                    {
                        items.map((item) => ( 
                        <tr key = { item.day } >
                            <td className = 'tablecol'>{ item.LogId }</td> 
                            <td className = 'tablecol'>{ item.UserId }</td> 
                            <td className='tablecol'>{ item.FirstName }</td>
                            <td className = 'tablecol'>{ item.LastName }</td>
                            <td className = 'tablecol'>{ item.EnterTime }</td>
                            <td className = 'tablecol'>{ item.ExitTime }</td>
                            <td className = 'tablecol'>{ item.GardenNumber }</td> 
                        </tr>
                        ))
                    }
                    </table>    
                </div>  
            </div>
        )
    }
}
export default GardenReport;
