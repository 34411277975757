import { useState } from "react";
import Button from "../components/layout/Button";
import FormInput from "../components/layout/FormInput";
import MainNavigation from "../components/NavigationBar/MainNavigation";
import stylesLogin from "../css/LoginPage.module.css";
import { createUser } from "../apis/apiClient";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const nav = useNavigate();
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    birthday: "",
    password: "",
    confirmPassword: "",
    address: "",
  });

  const inputs = [
    {
      id: 0,
      name: "firstName",
      type: "text",
      placeholder: "",
      errorMessage: "",
      label: "FirstName",
      required: false,
    },
    {
      id: 1,
      name: "lastName",
      type: "text",
      placeholder: "",
      errorMessage: "Last Name field should be valid",
      label: "LastName",
      required: true,
    },
    {
      id: 2,
      name: "vehiclePlate",
      type: "text",
      placeholder: "",
      errorMessage: "",
      label: "Vehicle Plate",
      required: true,
    },
    {
      id: 3,
      name: "dlNumber",
      type: "text",
      placeholder: "",
      errorMessage: "",
      label: "Driver License Number",
      required: true,
    },
    {
      id: 4,
      name: "email",
      type: "email",
      placeholder: "",
      errorMessage: "It should be a valid email address!",
      label: "Email",
      required: true,
    },
    {
      id: 5,
      name: "phone_number",
      type: "text",
      placeholder: "",
      errorMessage:
        "Please enter a valid phone number.It should have 10 digits",
      label: "Phone Number",
      pattern: "[0-9]{10}",
      required: true,
    },
    {
      id: 6,
      name: "password",
      type: "password",
      placeholder: "",
      errorMessage:
        "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!",
      label: "Password",
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
    },
    {
      id: 7,
      name: "confirmPassword",
      type: "password",
      placeholder: "",
      errorMessage: "Passwords don't match!",
      label: "Confirm Password",
      pattern: values.password,
      required: true,
    },
  ];

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let request = {
      first_name : values["firstName"],
      last_name : values["lastName"],
      vehicle_plate: values["vehiclePlate"],
      dl_number : values["dlNumber"],
      phone_number : values["phone_number"],
      password : values["password"],
      email : values["email"],
      role_id: 7 
    }
    //hardcoded role_id since only visitors can sign up
    createUser(request).then((response) => {
        console.log(response);
        nav('/login');
      }).catch((err) => {
        console.error(err);
        alert(err.response.data.errorMessage);
      });


  };

  return (
    <div>
      <MainNavigation />
      <div className={stylesLogin.pjwrapper}>
        <div className={stylesLogin.signUp}>
          <h2>Sign Up</h2>
          <form onSubmit={handleSubmit}>
            <div className={stylesLogin.row}>
              <div className={stylesLogin.column}>
                <FormInput
                  key={inputs[0].id}
                  {...inputs[0]}
                  value={values[inputs[0].name]}
                  onChange={onChange}
                />
              </div>
              <div className={stylesLogin.column}>
                <FormInput
                  key={inputs[1].id}
                  {...inputs[1]}
                  value={values[inputs[1].name]}
                  onChange={onChange}
                />
              </div>
            </div>

            <div className={stylesLogin.row}>
              <div className={stylesLogin.column}>
                <FormInput
                  key={inputs[2].id}
                  {...inputs[2]}
                  value={values[inputs[2].name]}
                  onChange={onChange}
                />
              </div>
              <div className={stylesLogin.column}>
                <FormInput
                  key={inputs[3].id}
                  {...inputs[3]}
                  value={values[inputs[3].name]}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className={stylesLogin.row}>
              <div className={stylesLogin.column}>
                <FormInput
                  key={inputs[4].id}
                  {...inputs[4]}
                  value={values[inputs[4].name]}
                  onChange={onChange}
                />
              </div>
              <div className={stylesLogin.column}>
                <FormInput
                  key={inputs[5].id}
                  {...inputs[5]}
                  value={values[inputs[5].name]}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className={stylesLogin.row}>
              <div className={stylesLogin.column}>
                <FormInput
                  key={inputs[6].id}
                  {...inputs[6]}
                  value={values[inputs[6].name]}
                  onChange={onChange}
                />
              </div>
              <div className={stylesLogin.column}>
                <FormInput
                  key={inputs[7].id}
                  {...inputs[7]}
                  value={values[inputs[7].name]}
                  onChange={onChange}
                />
              </div>
            </div>
            <Button name="btnLogin" value="Sign Up" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
