import styles from "../../css/MainNavigation.module.css";
import { Link } from "react-router-dom";


function PoolManagerNavigationPane() {
  return (
    <div className="container">
      <header className={styles.mainNav}>
        <nav>
        <div>
            <ul>
                <li><Link to="/">LogOut</Link></li>
                <li><Link to="/PoolManager">Pool Timings</Link></li>
                <li><Link to="/PoolManagerVisitors">Visitors</Link></li>
                <li><Link to="/PoolManagerResidents">Residents</Link></li>
                <li><Link to="/PoolReport">Pool Report</Link></li>
                <li><Link to="/PoolManagerSelectChat">Chat</Link></li>
                <li><Link to="/report">Report</Link></li>
            </ul>
        </div>
        </nav>
      </header>
    </div>
  );
}

export default PoolManagerNavigationPane;
