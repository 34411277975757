import React, { Component } from 'react'
import '../../css/UserDashBoard.css'
import DashBoardNavigation from './DashBoardNavigation'

export class UserDashboardVehicleAdd extends Component {
    constructor()
    {
        super()
        this.state={
            Email:'',
            NumberPlate:'',
            VehicleMake:'',
            OwnerName:'',
            APtNumber:'',
        }
    }
   componentDidMount()
   {
    this.setState({
        Email:localStorage.getItem('userId')
    })
   }
  render() {
    const chngNumberPlate=(event)=>{
        this.setState({
            NumberPlate:event.target.value
        })
    }
    const chngVehicleMake=(event)=>{
        this.setState({
            VehicleMake:event.target.value
        })
    }

    const chngOwnerName=(event)=>{
        this.setState({
            OwnerName:event.target.value
        })
    }

    const chngAPtNumber=(event)=>{
        this.setState({
            APtNumber:event.target.value
        })
    }
        const handleSubmit = async (event) => {
            event.preventDefault()
            const response = await fetch('http://terrazasapp.dxj0015.uta.cloud/terrazas-de-gucaco-backend/Resident.php/vehicles',{
                method:'Post',
                body:JSON.stringify({
                    Email:this.state.Email,
                    NumberPlate:this.state.NumberPlate,
                    VehicleMake:this.state.VehicleMake,
                    OwnerName:this.state.OwnerName,
                    APtNumber:this.state.APtNumber
                })
            });
            this.setState({
                NumberPlate:'',
                VehicleMake:'',
                APtNumber:'',
            })
          }
    return (
        <div>
      <DashBoardNavigation/>
      <div class='vehicle-card'>
        <h1>Manage your  Vehicles!!</h1>
                <div class="container-vehicle">
                <form action="/UserDashboardVehicleAdd" onSubmit={handleSubmit}>
                    <div>
                    Car number plate<div class="row">
                            <div class="col">
                            <input type="text" id="NumberPlate" value={this.state.NumberPlate} name="NumberPlate" placeholder="NumberPlate" onChange={chngNumberPlate}/>
                            </div>
                        </div>
                    <div class="vehicle-form-group">
                        Car maker<div class="row">
                            <div class="col">
                                <input type="text" id="amount" value={this.state.VehicleMake} name="VehicleMake" placeholder="VehicleMake" onChange={chngVehicleMake}/>
                            </div>
                        </div>
                    </div>
                    {/* <div class="vehicle-form-group">
                        owner name
                        <div class="row">
                            <div class="col">
                                <input type="text" id="amount" name="OwnerName" placeholder="OwnerName" onChange={chngOwnerName}/>
                            </div>
                        </div>
                    </div> */}

                    <div class="vehicle-form-group">
                        owner apt number
                        <div class="row">
                            <div class="col">
                                <input type="text" id="amount" value={this.state.APtNumber} name="APtNumber" placeholder="APtNumber" onChange={chngAPtNumber}/>
                            </div>
                        </div>
                    </div>
                    {/* <button class="signup-btn" type="submit">edit details</button> */}
                  <input typeof='submit' class="signup-btn" type="submit" value='Add more vehicles'/>               
                </div>
                </form>
            </div>                
      </div>
      </div>
    )
  }
}

export default UserDashboardVehicleAdd