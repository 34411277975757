import React, { Component } from 'react'

export class TableRows extends Component {
  render() {
    const arr=[]
    for(const key in this.props)
    {
        arr.push(this.props[key])
    }
    return (
        <tr>
        {arr.map(ar => (
        <td>{ar}</td>
         ))}
        </tr>
    )
  }
}

export default TableRows
