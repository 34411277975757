import "./PoolManagerResident.css";
import { useState, useEffect } from "react";
import PoolManagerNavigationPane from "./PoolManagerNavigationPane";
import Loader from "../spinning-loader/loader";
import axios from "axios";

const PoolManagerResidents = (props) => {

    const baseClient = axios.create({
        baseURL:
          "https://terrazasapp.dxj0015.uta.cloud/terrazas-de-gucaco-backend/",
          //"http://localhost/terrazas-de-gucaco-backend/",
      });

  const [items, setItems] = useState([]);
  const [Id, setId] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [TimeSlot, setTimeSlot] = useState("");
  const [Pool, setPool] = useState("");
  const [DataisLoaded, setDataisLoaded] = useState(false);

 

  const deleteResident = async (residentId) => {
    const response = await baseClient.delete(`/Pool.php/resident?Id=${residentId}`);
    return response.data;
  };

  const getAllPoolResidents = async () => {
    const response = await baseClient.get("/Pool.php/resident");
    return response.data;
  };

  useEffect(() => {
    getAllPoolResidents()
      .then((response) => {
        console.log(response);
        setItems(response);
        setDataisLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = async (event) => {
   event.preventDefault();
      let request = {
        Id: Id,
        FirstName: FirstName,
        LastName: LastName,
        TimeSlot:TimeSlot,
        Pool:Pool
      }
    
      deleteResident(Id)
      .then((response) => {
        console.log(response)
        setItems((records) =>
          records.filter((record) => record.LogId != Id)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };



  if (!DataisLoaded) {
    return <Loader />;
  }

  return (
    <div>
      <PoolManagerNavigationPane />
      <div>
        Residents to come tomorrow.
        <table className="tabletop">
          <thead style={{ backgroundColor: "#f8f8f8" }}>
            <tr>
              <th className="tablehead">Id</th>
              <th className="tablehead">FirstName</th>
              <th className="tablehead">LastName</th>
              <th className="tablehead">EnterTime</th>
              <th className="tablehead">ExitTime</th>
              <th className="tablehead">Pool</th>
            </tr>
          </thead>
          {items.map((item) => (
            <tr key={item.day}>
              <td className="tablecol">{item.LogId}</td>
              <td className="tablecol">{item.FirstName}</td>
              <td className="tablecol">{item.LastName}</td>
              <td className="tablecol">{item.EnterTime}</td>
              <td className="tablecol">{item.ExitTime}</td>
              <td className="tablecol">{item.PoolNumber}</td>
            </tr>
          ))}
        </table>
      </div>

      <div style={{ marginTop: "10px" }}>
        <form
          onSubmit={handleSubmit}
          style={{
            fontFamily: "Arial, sans-serif",
            fontSize: "16px",
            margin: "20px",
          }}
        >
          <label
            htmlFor="ID"
            style={{ display: "block", marginBottom: "10px" }}
          >
            ID to remove:
          </label>
          <input
            type="text"
            id="Id"
            name="Id"
            style={{
              padding: "10px",
              fontSize: "16px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              marginBottom: "20px",
            }}
            onChange={(e) => setId(e.target.value)}
          ></input>
          <input
            type="submit"
            value="Remove"
            style={{
              backgroundColor: "#4CAF50",
              color: "white",
              padding: "10px 20px",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
            }}
          ></input>
        </form>
      </div>
    </div>
  );
};

export default PoolManagerResidents;
