import React, { useEffect, useState } from "react";
import Graphs from "./Graphs";
import styles from "./DashBoard.module.css";
import Button from "../layout/Button";
import { getAllVisitors,getAllResidents,getAllSecurity,getAllManagers } from "../../apis/apiClient";


function DashBoard(props) {
  const [defaultView, setDefaultView] = useState(true);
  const [securityView, setSecurityView] = useState(false);
  const [visitorLength, setVisitorLength] = useState(0);
  const [residentLength, setResidentLength] = useState(0);
  const [guardsLength, setGuardsLength] = useState(0);
  const [managersLength, setManagersLength] = useState(0);

  useEffect(() => {
    getAllVisitors()
      .then((response) => {
        setVisitorLength(response.data.length)
      })
      .catch((err) => {
        console.log(err);
      });

      getAllResidents()
      .then((response) =>{
        setResidentLength(response.data.length)
      })
      .catch((err) =>{
        console.log(err);
      })
  }, []);

  const data = {
    labels: ["Residents", "Visitors"],
    datasets: [
      {
        label: "Employee Count",
        data:  [residentLength, visitorLength],
        backgroundColor: ["rgba(36, 70, 139,0.4)", "rgba(255, 127, 80,0.7)"],
      },
    ],
  };

  const data2 = {
    labels: ["Guards", "Managers"],
    datasets: [
      {
        label: "Employee count",
        data: [guardsLength, managersLength],
        backgroundColor: ["rgba(128, 0, 128,0.4)", "rgba(255, 215, 0,0.7)"],
      },
    ],
  };

  const handleButtonClick = (view) => {
    if (view === "default") {
      setDefaultView(true);
      setSecurityView(false);
    } else if (view === "security") {
      getAllManagers()
      .then((response) =>{
        setManagersLength(response.data.length)
      })
      .catch((err) =>{
        console.log(err);
      })

      getAllSecurity()
      .then((response) =>{
        setGuardsLength(response.data.length)
      })
      .catch((err) =>{
        console.log(err);
      })
      setSecurityView(true);
      setDefaultView(false);
    }
  };

  return (
    <div className={styles.pjwrapper}>
      <div className={styles.leftColumn}>
        <Button name="btnPrimary" value="Resident/Visitors" handleClick={() => handleButtonClick('default')} />
        <Button name="btnPrimary" value="Security/Managers" handleClick={() => handleButtonClick('security')} />
      </div>
      <div className={styles.rightColumn}>
        {defaultView && <Graphs  data={data} />}
        {securityView && <Graphs data={data2} />}
      </div>
    </div>
  );
}

export default DashBoard;