import { Link } from "react-router-dom";
import styles from "../../css/MainNavigation.module.css";

function MainNavigation() {
  return (
    <div className={styles.wrapper}>
      <nav>
        <div className={styles.logo}>
          <h4>Terrezas De Gucaco</h4>
        </div>
        <ul className={styles.navLinks}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/services">Services</Link>
          </li>
          <li>
            <Link to="/login">Login/Sign up</Link>
          </li>
          <li>
            <Link to="/contact-us">Contact Us</Link>
          </li>
          <li>
            <Link to="/about">About Us</Link>
          </li>
          <li>
            <Link to="https://wdm17.wordpress.com/">Blog</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default MainNavigation;
