import './GardenManagerVisitor.css'
import { Component } from 'react';
import GardenManagerNavigationPane from './GardenManagerNavigationPane';
class GardenManagerVisitors extends Component{
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            log_id:'',
            DataisLoaded: false
        };
        this.handleSubmit=this.handleSubmit
        this.handleLog_Id=this.handleLog_Id
    }
    componentDidMount()
    {
        fetch(
            "http://terrazasapp.dxj0015.uta.cloud/terrazas-de-gucaco-backend/Visitor.php/log")
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    items: json,
                    DataisLoaded: true
                })      
        })
    };
    handleSubmit = async (event) => {
        // event.preventDefault()
        const response = await fetch('http://terrazasapp.dxj0015.uta.cloud/terrazas-de-gucaco-backend/Visitor.php/log-delete',{
            method:'Post',
            body:JSON.stringify({'log_id':this.state.log_id})
        });

        this.componentDidMount();
      }
    
    render(){

        const { DataisLoaded, items } = this.state;   
        const handleLog_Id =(event)=>
        {   
            this.setState({
                log_id:event.target.value
            })
        }
        return(
            <div>
            <GardenManagerNavigationPane /> 
            <div>
                <table className='tabletop'>
                <thead style={{backgroundColor: '#f8f8f8'}}>
                    <tr>
                        <th className = 'tablehead'>log_id</th>
                        <th className = 'tablehead'>First name</th>
                        <th className = 'tablehead'>Last Name</th>
                        <th className = 'tablehead'>Time slot</th>
                        <th className = 'tablehead'>garden_id</th>
                    </tr>
                    </thead>
                    {
                        items.map((item) => ( 
                        <tr key = { item.day } >
                            <td className = 'tablecol'>{ item.LogId }</td> 
                            <td className='tablecol'>{ item.FirstName }</td>
                            <td className = 'tablecol'>{ item.LastName }</td>
                            <td className = 'tablecol'>{ item.EnterTime }</td>
                            <td className = 'tablecol'>{ item.GardenNumber }</td> 
                        </tr>
                        ))
                    }
                    </table>
                        <div style={{marginTop: '10px'}}>
                            <form style={{fontFamily: 'Arial, sans-serif', fontSize: '16px', margin: '20px'}} onSubmit={this.handleSubmit} action='/GardenManagerVisitors'>
                                <label htmlFor="ID" style={{display: 'block', marginBottom: '10px'}}>ID to remove:</label>
                                <input type="text" id="ID" name="log_id" style={{padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc', marginBottom: '20px'}} onChange={handleLog_Id}></input>
                                <input type="submit" value="Remove" style={{backgroundColor: '#4CAF50', color: 'white', padding: '10px 20px', borderRadius: '5px', border: 'none', cursor: 'pointer'}}></input>
                            </form> 
                        </div>
                    </div>
            </div>
        );
    }
}
export default GardenManagerVisitors;
