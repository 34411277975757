import React from "react";
import DataTable from "react-data-table-component";
import btnStyles from "./ReadOnlyTable.module.css";


const ReadOnlyTable = (props) => {

  const onEditClick = (event, row) => {
    event.preventDefault();
    props.handleEditClick(row);
  };

  const onDeleteClick = (event, row) => {
    event.preventDefault();
    props.handleDeleteClick(row);
  };

  const handleGrantAccess = (event, row) => {
    console.log(row);
    event.preventDefault();
    props.handleGrantAccess(row, 1);
  };

  const handleRevokeAccess = (event, row) => {
    event.preventDefault();
    props.handleRevokeAccess(row, 0);
  };


  let columns = [
    {
      name: "Id",
      selector: (row) => row.security_id,
    },
    {
      name: "Name",
      selector: (row) => (row.first_name + " " + row.last_name),
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Phone",
      selector: (row) => row.phone_number,
    },
  ];

  if (props.view === "security") {
    columns = columns.concat([
      {
        name: "",
        cell: (row) => (
          <div>
            <button
              className={btnStyles.btnPrimary}
              onClick={(event) => onEditClick(event, row)}
            >
              Edit
            </button>
            <button
              className={btnStyles.btnDanger}
              onClick={(event) => onDeleteClick(event, row)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ]);
  } else if (props.view === "visitor") {
    columns[0] = {
      name: "Id",
      selector: (row) => row.visitor_id,
    }
    columns = columns.concat([
      {
        name: "Vehicle Plate",
        selector: (row) => row.vehicle_plate,
      },
      {
        name: "",
        cell: (row) => (
          <div>
            <button
              className={btnStyles.btnPrimary}
              onClick={(event) => onEditClick(event, row)}
            >
              Edit
            </button>
            <button
              className={btnStyles.btnDanger}
              onClick={(event) => onDeleteClick(event, row)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ]);
  } else if (props.view === "resident") {
    columns[0] = {
      name: "Id",
      selector: (row) => row.resident_id,
    }
    columns[3] = {
      name: "Phone",
      selector: (row) => row.phone,
    }
   
    columns = columns.concat([
      {
        name: "Building Number",
        selector: (row) => row.building_id,
      },
      {
        name: "Unit Number",
        selector: (row) => row.unit_number,
      },
      {
        name: "BirthDay",
        selector: (row) => row.DOB,
      },
      {
        name: "",
        cell: (row) => (
          <div>
            <button
              className={btnStyles.btnPrimary}
              onClick={(event) => onEditClick(event, row)}
            >
              Edit
            </button>
            <button
              className={btnStyles.btnDanger}
              onClick={(event) => onDeleteClick(event, row)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ]);
  }else if (props.view === "managers") {
    columns = [
      {
        name: "Id",
        selector: (row) => row.mgr_id,
      },
      {
        name: "Name",
        selector: (row) => (row.first_name + " " + row.last_name),
      },
      {
        name: "phone",
        selector: (row) => row.phone_number,
      },
      {
        name: "email",
        selector: (row) => row.email,
      },
      {
        name: "title",
        selector: (row) => row.mgr_title,
      },
      {
        name: "",
        cell: (row) => (
          <div>
            <button
              className={btnStyles.btnPrimary}
              onClick={(event) => onEditClick(event, row)}
            >
              Edit
            </button>
            <button
              className={btnStyles.btnDanger}
              onClick={(event) => onDeleteClick(event, row)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ];
  }
  return (
    <div>
      <DataTable columns={columns} data={props.data} />
    </div>
  );
};

export default ReadOnlyTable;
