import stylesInfocard from "../../css/InfoCard.module.css";

function InfoCard(props) {
  return (
    <div className={stylesInfocard.infoCard}>
      <img src={props.imgPath} alt={props.title} />
      <p>{props.content} </p>
    </div>
  );
}

export default InfoCard;
