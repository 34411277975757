import { Link,useNavigate } from "react-router-dom";
import styles from "./Navigation.module.css";
const Navigation = (props) => {
  const nav = useNavigate();
  function handleLogOut() {
    localStorage.removeItem("userId");
    nav("/");
  }
  return (
    <div className={styles.pjwrapper}>
      <nav>
        <div className={styles.logo}>
          <h4>Terrezas De Gucaco</h4>
        </div>
        <ul className={styles.navLinks}>
          <li>
            <Link to="profile">Profile</Link>
          </li>
          <li>
            <Link to="timings">Timings</Link>
          </li>
          <li>
            <Link to="directions">Get Directions</Link>
          </li>
          <li>
            <Link to="events">Events</Link>
          </li>
          <li>
            <Link to="chat">Chat</Link>
          </li>
          <li>
            <button className={styles.logoutBtn} onClick={handleLogOut}>Logout</button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
