import React, { Component } from 'react'
import TableHeader from './TableHeader'
import UserDashBoardTableRows from './UserDashBoardTableRows'

export class Booking extends Component {
  constructor(props)
  {
      super(props)
      this.state={
          items: [],
          Email:'',
          Pool:'',
          Garden:'',
      }
  }
  render() {
    const chngPool=(event) =>{
        this.setState({
          Pool:event.target.value
        })
    }
    const chngGarden=(event) =>{
      this.setState({
        Garden:event.target.value
      })
    }
    const handleSubmit = async (event) => {
      // event.preventDefault()
      const response = await fetch('http://terrazasapp.dxj0015.uta.cloud/terrazas-de-gucaco-backend/Resident.php/booking',{
          method:'Post',
          body:JSON.stringify({'Email':this.state.Email,'Pool':this.state.Pool,'Garden':this.state.Garden})
      });
    }
    return (
        <table>
            <TableHeader heading='Booking' col1='Club name' col2='membership' col3='submit'/>
            <UserDashBoardTableRows val1='Garden Club' val2={<tr><label for="datetime">Select Date and Time:</label><input type="datetime-local" id="datetime" name="datetime" onChange={chngPool}></input></tr>} val3={<form action='/UserDashboardActivity' onSubmit={handleSubmit}><input type='submit' value='save'/></form>}/>
            <UserDashBoardTableRows val1='Pool time' val2={<tr><label for="datetime">Select Date and Time:</label><input type="datetime-local" id="datetime" name="datetime" onChange={chngGarden}/></tr>} val3={<form action='/UserDashboardActivity' onSubmit={handleSubmit}><input type='submit' value='save'/></form>}/>
        </table>
    )
  }
}

export default Booking
