import React from 'react'
import TableRows from './TableRows'
import TableHeader from './TableHeader'
import BuildingManagerNavigation from './BuildingManagerNavigation'
import './BuildingManager.css'

export default function BuildingManager_pool() {
  return (
    <div>
        <BuildingManagerNavigation/>
      <div>
        <table class='building-manager-table'>
            <TableHeader heading='Pool managers' val1='ID' val2='FirstName' val3='LastName' val4='Join date' val5='Assigned site' />
            <TableRows val1='1' val2='pool manag1 name' val3='pool manag1 last name' val4='12/01/2020' val5='Block-A'/>
            <TableRows val1='2' val2='pool manag2 name' val3='pool manag2 last name' val4='01/02/2019' val5='Block-B'/>
            <TableRows val1='3' val2='pool manag3 name' val3='pool manag3 last name' val4='11/01/2021' val5='Block-C'/>
            <TableRows val1='4' val2='pool manag4 name' val3='pool manag4 last name' val4='05/10/2019' val5='Block-D'/>
        </table>
    </div>
    <div class="buildingmanager-form-container">
            <form class='building-form'>
                <h2>Add manager</h2>
                <label for="name">Name:</label>
                <input type="text" id="name" name="name" required/>
                <label for="email">Email:</label>
                <input type="email" id="email" name="email" required/>
                <label for="phone">phone:</label>
                <input type="text" id="phone" name="phone" required/>
                <input type="submit" value="Add"/>
            </form>

            <form class='building-form'>

            <h2>Update Delete manager</h2>
                <label for="guard">manager:</label>
                <select name="guard" id="guard">
                    <option value="Guard1">pool manag1</option>
                    <option value="Guard2">pool manag2</option>
                    <option value="Guard3">pool manag3</option>
                    <option value="Guard4">pool manag4</option>
                </select>

                <label for="name">manager:</label>
                  <input type="text" id="name" name="name" required/>
                  <label for="email">Email:</label>
                  <input type="email" id="email" name="email" required/>
                <label for="phone">phone:</label>
                    <input type="text" id="phone" name="phone" required/>
                    <input type="submit" value="Delete" />
                <input type="submit" value="Update"/>
            </form>
        </div>
    </div>
  )
}
