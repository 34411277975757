import React, { Component } from 'react'
import GardenTable from './GardenTable'
import PoolTable from './PoolTable'
import '../../css/UserDashBoard.css'
import DashBoardNavigation from "./DashBoardNavigation";

export class UserDashBoard extends Component {
  render() {
    return (
      <div >
        <DashBoardNavigation/> 
        <GardenTable/>  
        <PoolTable/>      
    </div>
    )
  }
}

export default UserDashBoard
