import React, { Component } from 'react'
import TableHeader from './TableHeader'
import UserDashBoardTableRows from './UserDashBoardTableRows'
export class PoolTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
        items: [],
        day:'',
        StartTime:'',
        EndTime:'',
        DataisLoaded: false
    };
    this.handleSubmit=this.handleSubmit
}
componentDidMount() {
    fetch(
        "http://terrazasapp.dxj0015.uta.cloud/terrazas-de-gucaco-backend/Pool.php/timings")
        .then((res) => res.json())
        .then((json) => {
            this.setState({
                items: json,
                DataisLoaded: true
            })
    })
};

render(){     
    const handleSubmit = async (event) => {
        // event.preventDefault()
        console.log('hello',this.state.day)
        const response = await fetch('http://terrazasapp.dxj0015.uta.cloud/terrazas-de-gucaco-backend/Pool.php/timings',{
            method:'Post',
            body:JSON.stringify({'day':this.state.day,'StartTime':this.state.StartTime,'EndTime':this.state.EndTime})
        });
      }

    const setDay=(event)=>{
        this.setState({day:event.target.value})
    }

    const setStartTime=(event)=>{
        this.setState({StartTime:event.target.value})
    }

    const setEndTime=(event)=>
    {this.setState({EndTime:event.target.value})
    }
    
    const { DataisLoaded, items } = this.state;
    return(
                <div>
                <table className='tabletop'>
            <thead style={{backgroundColor: '#f8f8f8'}}>
                <tr>
                    <th className = 'tablehead'>Day</th>
                    <th className = 'tablehead'>Start Time</th>
                    <th className = 'tablehead'>End Time</th>
                </tr>
                </thead>
                {
                    items.map((item) => ( 
                    <tr key = { item.day } >
                        <td className='tablecol'>{ item.Day }</td>
                        <td className = 'tablecol'>{ item.StartTime }</td>
                        <td className = 'tablecol'>{ item.EndTime }</td> 
                    </tr>
                    ))
                }
                </table>
            </div>  
        )
    }
}

export default PoolTable
