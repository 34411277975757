import btnStyles from "../../css/Button.module.css";


const Button = (props) => {
  return (
    <div className={btnStyles.pjwrapper}>
      <button className={btnStyles[props.name]} onClick={props.handleClick}>
        {props.value}
      </button>
    </div>
  );
};

export default Button;
