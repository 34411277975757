import React, { Component } from 'react'
import UserDashBoardTableRows from './UserDashBoardTableRows'
import DashBoardNavigation from './DashBoardNavigation'

export class UserDashboardVehicleView extends Component {
    constructor()
    {
        super()
        this.state={
            items:[],
            VehicleId:'',
            Email:'',
            NumberPlate:'',
            VehicleMake:'',
            OwnerName:'',
            APtNumber:'',
        }
        this.handleDelete=this.handleDelete;
    }
   componentDidMount()
   {
    console.log(localStorage.getItem('userId'))
    this.setState({
        Email:localStorage.getItem('userId')
    })
    fetch(
        "http://terrazasapp.dxj0015.uta.cloud/terrazas-de-gucaco-backend/Resident.php/vehiclesView",
        {
            method:'POST', 
            body:JSON.stringify({'Email':localStorage.getItem('userId')})
        }
    )
        .then((res) => res.json())
        .then((json) => {
            this.setState({
                items: json,
                DataisLoaded: true
            })
    })
   }
    handleDelete= (event)=>{
    event.preventDefault()   
    // http:///terrazas-de-gucaco-backend 
    const response =  fetch('http://terrazasapp.dxj0015.uta.cloud/terrazas-de-gucaco-backend/Resident.php/delete-vehicleId',
    {
        method:'POST',
        body:JSON.stringify({
            VehicleId: this.state.VehicleId
        })
    });
    const data = this.state.items.filter(d=> d["VehicleId"]!=this.state.VehicleId);
    this.setState({items:data});
    // this.componentDidMount();
}

  render() { 
    const { DataisLoaded, items } = this.state;
    const setVehicleId=(event)=>{
        this.setState({VehicleId:event.target.value})
    }

    return (
        <div>
        <DashBoardNavigation/>
      <div>
        <table className='tabletop'>
            <thead style={{backgroundColor: '#f8f8f8'}}>
                <tr>
                    <th className = 'tablehead'>VehicleId</th>
                    <th className = 'tablehead'>NumberPlate</th>
                    <th className = 'tablehead'>VehicleMake</th>
                    <th className = 'tablehead'>APtNumber</th>      
                </tr>
                </thead>
                {
                    items.map((item) => ( 
                    <tr key = { item.day } >
                        <td className='tablecol'>{ item.VehicleId }</td>
                        <td className='tablecol'>{ item.NumberPlate }</td>
                        <td className = 'tablecol'>{ item.VehicleMake }</td>
                        <td className = 'tablecol'>{ item.AptNumber }</td>  
                    </tr>
                    ))
                } 
                </table>


                <div style={{marginTop: '10px'}}>
                    <form onSubmit={this.handleDelete} style={{fontFamily: 'Arial, sans-serif', fontSize: '16px', margin: '20px'}} action='/UserDashboardVehicleView'>
                        <label htmlFor="ID" style={{display: 'block', marginBottom: '10px'}}>ID to remove:</label>
                        <input type="text" id="Id" name="Id" style={{padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc', marginBottom: '20px'}} onChange={setVehicleId}></input>
                        <input type="submit" value="Remove" style={{backgroundColor: '#4CAF50', color: 'white', padding: '10px 20px', borderRadius: '5px', border: 'none', cursor: 'pointer'}}></input>
                    </form> 
                </div>
      </div>
      </div>

    )
  }
}

export default UserDashboardVehicleView
