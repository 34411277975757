import { Link } from "react-router-dom";
import styles from "../../css/MainNavigation.module.css";

function DashBoardNavigation() {
  return (
    <div className="container">
      <header className={styles.mainNav}>
        <nav>
      {/* <input type="hidden" name="UserId" value={}/> */}
          <ul>
            <li>
              <Link to="/">Logout</Link>
            </li>
            <li>
              <Link to="/UserDashboard">Timing</Link>
            </li>
            <li>
              <Link to="/UserProfile">Profile</Link>
            </li>
            <li>
              <Link to="/UserDashboardActivity">Activity</Link>
            </li>
            <li>
              <Link to="/UserDashboardVehicleView">VehicleView</Link>
            </li>
            <li>
              <Link to="/UserDashboardVehicleAdd">VehicleAdd</Link>
            </li>
            <li>
              <Link to="/UserDashboardSelectChat">Chat</Link>
            </li>
            
          </ul>
        </nav>
      </header>
    </div>
  );
}

export default DashBoardNavigation;
