import React from 'react'
import TableRows from './TableRows'
import TableHeader from './TableHeader'
import BuildingManagerNavigation from './BuildingManagerNavigation'
import './BuildingManager.css'

export default function BuildingManagerDashBoard() {
  return (      
      <div>
        <BuildingManagerNavigation/>
        <table class='building-manager-table'>
          <TableHeader heading='Log' val1='ID' val2='FirstName' val3='LastName' val4='Date' val5='Time' val6='Block'/>
          <TableRows val1='1' val2='VisitorFirstname1' val3='VisitorLastname1' val4='11/02/2019' val5='9:00AM-10:00AM' val6='A'/>
          <TableRows val1='2' val2='VisitorFirstname2' val3='VisitorLastname2' val4='12/24/2019' val5='9:00AM-10:00AM' val6='B'/>
          <TableRows val1='3' val2='VisitorFirstname3' val3='VisitorLastname3' val4='01/22/2019' val5='9:00AM-10:00AM' val6='C'/>
          <TableRows val1='4' val2='VisitorFirstname4' val3='VisitorLastname4' val4='04/12/2019' val5='9:00AM-10:00AM' val6='D'/>
        </table>
    </div>
  )
}
