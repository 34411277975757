import React from 'react'
import BuildingManagerNavigation from './BuildingManagerNavigation'
import TableHeader from './TableHeader'
import TableRows from './TableRows'
import './BuildingManager.css'


export default function BuildingManager_garden() {
  return (
    <div>
        <BuildingManagerNavigation/>
        <table class='building-manager-table'>
          <TableHeader heading='Garden manager' val1='ID' val2='FirstName' val3='LastName' val4='Date' val5='Time' val6='Pool'/>
          <TableRows val1='1' val2='VisitorFirstname1' val3='VisitorLastname1' val4='11/02/2019' val5='9:00AM-10:00AM' val6='A'/>
          <TableRows val1='2' val2='VisitorFirstname2' val3='VisitorLastname2' val4='12/24/2019' val5='9:00AM-10:00AM' val6='B'/>
          <TableRows val1='3' val2='VisitorFirstname3' val3='VisitorLastname3' val4='01/22/2019' val5='9:00AM-10:00AM' val6='C'/>
          <TableRows val1='4' val2='VisitorFirstname4' val3='VisitorLastname4' val4='04/12/2019' val5='9:00AM-10:00AM' val6='D'/>
        </table>
        <div class="buildingmanager-form-container">
            <form class='building-form'>
                <h2>Add manager</h2>
                <label for="name">Name:</label>
                <input type="text" id="name" name="name" required/>
                <label for="email">Email:</label>
                <input type="email" id="email" name="email" required/>
                <label for="phone">phone:</label>
                <input type="text" id="phone" name="phone" required/>
                <input type="submit" value="Add"/>
            </form>

            <form class='building-form'>

            <h2>Update Delete manager</h2>
                <label for="guard">manager:</label>
                <select name="guard" id="guard">
                    <option value="Guard1">garden manag1</option>
                    <option value="Guard2">garden manag2</option>
                    <option value="Guard3">garden manag3</option>
                    <option value="Guard4">garden manag4</option>
                </select>

                <label for="name">manager:</label>
                  <input type="text" id="name" name="name" required/>
                  <label for="email">Email:</label>
                  <input type="email" id="email" name="email" required/>
                <label for="phone">phone:</label>
                    <input type="text" id="phone" name="phone" required/>
                    <input type="submit" value="Delete" />
                <input type="submit" value="Update"/>
            </form>
        </div>
    </div>
  )
}
