import stylesServices from "../css/ServicesPage.module.css";
import MainNavigation from "../components/NavigationBar/MainNavigation";
function ServicesPage() {
  return (
    <div>
    <MainNavigation />
    <div className={stylesServices.container}>
      <div className={stylesServices.content}>
        <div className={stylesServices.card}>
          <img src="../images/building.jpg" alt="building.jpg" />
          <h4>Manage Buildings</h4>
          <p>
            In this society all the buildings are well maintained by our
            privileged team of high qualified managers. Our managers are always
            available in their office hours and for any emergency. Please
            contact our emergency number (897)-686-7865.{" "}
          </p>
        </div>
        <div className={stylesServices.card}>
          <img
            src="../images/garden_and_surroundings.jpg"
            alt="surroundings2.jpg"
            />
          <h4>Management of Garden and Surroundings</h4>
          <p>
            In this society all the garden and pools are well maintained by our
            privileged team of high qualified managers. Our managers are always
            available in their office hours and for any emergency. Please
            contact our emergency number <nobr>(897)-686-7865.</nobr>
          </p>
        </div>

        <div className={stylesServices.card}>
          <img src="../images/management_of_visitors.jpg" alt="schedule.jpg" />
          <h4>Management of Visitors</h4>
          <p>
            Come see us. you can shedule a visit for your loved ones staying
            here. Please enter all the details necessary and schedule a visit to
            our society.
          </p>
        </div>
      </div>
    </div>
  </div>
  );
}

export default ServicesPage;
