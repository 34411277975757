import React, { useState } from "react";
import BarChart from "../chart/BarChart";
import styles from "./Graphs.module.css";

import PieChart from "../chart/PieChart";

function Graphs(props) {
  const [graphData, setGraphData] = useState(props.data);
  return (
    <div className={styles.pWrapper}>
      <div className={styles.sBar}>
        <BarChart data={props.data} />
      </div>
      <div className={styles.pBar}>
        <PieChart data={props.data} />
      </div>
    </div>
  );
}

export default Graphs;