import { useState } from "react";
import Button from "../components/layout/Button";
import FormInput from "../components/layout/FormInput";
import MainNavigation from "../components/NavigationBar/MainNavigation";
import stylesLogin from "../css/LoginPage.module.css";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../apis/apiClient";

const ForgotPassword = (props) => {
  const nav = useNavigate();

  const input = {
    id: 1,
    name: "email",
    type: "email",
    placeholder: "Email",
    errorMessage: "It should be a valid email address!",
    label: "Email",
    required: true,
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const request = {
      email: values["email"],
    };
    forgotPassword(request)
      .then((response) => {
        if (response.status_code == 401) {
          alert(response.errorMessage);
        } else {
          alert(response.successMessage);
          nav("/login");
        }
      })
      .catch((err) => {
        console.error(err);
        alert(err.response.data.errorMessage);
      });
  };

  const handleCancel = () => {
    nav(-1);
  };

  const [values, setValues] = useState({ email: "" });
  return (
    <div>
      <MainNavigation />
      <div className={stylesLogin.pjwrapper}>
        <div className={stylesLogin.forgotPass}>
          <h2>Forgot Password</h2>
          <form onSubmit={handleSubmit}>
            <FormInput
              key={input.id}
              {...input}
              value={values[input.name]}
              onChange={onChange}
            />
            <div className={stylesLogin.btnDisp}>
              <Button
                name="btnLogin"
                value="Reset Password"
                handleClick={handleSubmit}
              />
              {/*<Button
                name="btnLogin"
                value="Cancel"
                handleClick={handleCancel}
              />*/}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
