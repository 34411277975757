import React, { Component } from 'react'
import TableHeader from './TableHeader'
import UserDashBoardTableRows from '../UserDashBoard/UserDashBoardTableRows'
import BuildingManagerNavigation from './BuildingManagerNavigation'

export class BuildingManagerSelectChat extends Component {
  render() {
    return (
      <div>
        <BuildingManagerNavigation/>
        <div>
            <TableHeader heading='ChatBox' col1='Person' col2='Select'  />
            <UserDashBoardTableRows val1='person 1' val2={<form action='/BuildingManagerChat'><input type='submit' value='chat'/></form>}/>
            <UserDashBoardTableRows val1='person 2' val2={<form action='/BuildingManagerChat'><input type='submit' value='chat'/></form>}/>
            <UserDashBoardTableRows val1='person 3' val2={<form action='/BuildingManagerChat'><input type='submit' value='chat'/></form>}/>
            <UserDashBoardTableRows val1='person 4' val2={<form action='/BuildingManagerChat'><input type='submit' value='chat'/></form>}/>
            <UserDashBoardTableRows val1='person 5' val2={<form action='/BuildingManagerChat'><input type='submit' value='chat'/></form>}/>
        </div>
      </div>
    )
  }
}

export default BuildingManagerSelectChat
