import './GardenManager.css'
import { Component } from 'react';
import GardenManagerNavigationPane from './GardenManagerNavigationPane';
import axios from 'axios';


class GardenManager extends Component{
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            day:'',
            StartTime:'',
            EndTime:'',
            DataisLoaded: false
        };
        this.handleSubmit=this.handleSubmit
    }
    dayData={
        0:"Monday",
        1:"Tuesday",
        2:"Wednesday",
        3:"Thursday",
        4:"Friday",
        5:"Saturday",
        6:"Sunday",
    }
    componentDidMount() {
        fetch(
            "http://terrazasapp.dxj0015.uta.cloud/terrazas-de-gucaco-backend/GardenTiming.php/timings")
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    items: json,
                    DataisLoaded: true
                })
        })
    };
    handleSubmit = async (event) => {
        event.preventDefault()
        const response = await fetch('http://terrazasapp.dxj0015.uta.cloud/terrazas-de-gucaco-backend/GardenTiming.php/timings',{
            method:'Post',
            body:JSON.stringify({'day':this.state.day,'StartTime':this.state.StartTime,'EndTime':this.state.EndTime})
        });
        this.componentDidMount();

      }

    render(){   

        const setDay=(event)=>{
            this.setState({day:event.target.value})
        }

        const setStartTime=(event)=>{
            this.setState({StartTime:event.target.value})
        }

        const setEndTime=(event)=>
        {this.setState({EndTime:event.target.value})
        }
        
        const { DataisLoaded, items } = this.state;   
        return(
                <div>
                    <GardenManagerNavigationPane />
                <div>
                <table className='tabletop'>
                <thead style={{backgroundColor: '#f8f8f8'}}>
                    <tr>
                        <th className = 'tablehead'>Day</th>
                        <th className = 'tablehead'>Start Time</th>
                        <th className = 'tablehead'>End Time</th>
                    </tr>
                    </thead>
                    {
                        items.map((item,index) => ( 
                            <tr key={this.dayData[index]} >
                                <td className='tablecol'>{ this.dayData[index] }</td>
                                <td className = 'tablecol'>{ items.filter(d=>d.Day==this.dayData[index])[0]["StartTime"] }</td>
                                <td className = 'tablecol'>{ items.filter(d=>d.Day==this.dayData[index])[0]["EndTime"] }</td> 
                            </tr>
                        ))
                    }
                    </table>
                </div>
                        
                <div style={{marginTop: '10px'}}>
                    <form onSubmit={this.handleSubmit} action='/GardenManager'>
                        <label htmlFor="days"className='label1'>Day:</label>
                        <select name="days" className='days' onChange={setDay}>
                        <option value="Monday">Monday</option>
                        <option value="Tuesday">Tuesday</option>
                        <option value="Wednesday">Wednesday</option>
                        <option value="Thursday">Thursday</option>
                        <option value="Friday">Friday</option>
                        <option value="Saturday">Saturday</option>
                        <option value="Sunday">Sunday</option>
                        </select>
                        <label htmlFor="StartTime" style={{display: 'block', marginBottom: '10px'}}>Start Time:</label>
                        <input type="text" id="StartTime" name="StartTime" style={{padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid black', solid: '#ccc', marginBottom: '20px'}} onChange={setStartTime}></input>
                        <label htmlFor="EndTime" style={{display: 'block', marginBottom: '10px'}}>End Time:</label>
                        <input type="text" id="EndTime" name="EndTime" style={{padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid black', solid: '#ccc', marginBottom: '20px'}} onChange={setEndTime}></input>
                        <input type="submit" value="Submit" style={{backgroundColor: '#4CAF50', color: 'white', padding: '10px 20px', borderRadius: '5px', border: 'none', cursor: 'pointer'}}></input>
                    </form>
                </div>
                        
                </div>
        )
    }
}

export default GardenManager;