import React, { Component } from 'react'
import Booking from './Booking'
import ClubMembership from './ClubMembership'
import DashBoardNavigation from './DashBoardNavigation'

export class UserDashBoardActivityPage extends Component {
  render() {
    return (
      <div>
      <DashBoardNavigation/>
      <div class='activity-card'>
          <ClubMembership/>
          <Booking/>
      </div>
      </div>
    )
  }
}

export default UserDashBoardActivityPage
