import { useState } from "react";
import formStyles from "../../css/FormInputv2.module.css";

const FormInputv2 = (props) => {
  const [focused, setFocused] = useState(false);
  const { label, errorMessage, onChange, id, ...inputProps } = props;

  const handleFocus = (e) => {
    setFocused(true);
  };
  return (
    <div className={formStyles.pjwrapper}>
      <label>{label}</label>
      <input
      className={formStyles.formInput}
        {...inputProps}
        onChange={onChange}
        onBlur={handleFocus}
        focused={focused.toString()}
      />
      <span>{errorMessage}</span>
    </div>
  );
};

export default FormInputv2;
