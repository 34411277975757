import { Routes, Route } from "react-router-dom";

import AboutPage from "./pages/AboutPage";
import ContactUsPage from "./pages/ContactUsPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import ServicesPage from "./pages/ServicesPage";
import styles from "./App.module.css";
import ForgotPassword from "./pages/ForgotPassword";
import SignUpPage from "./pages/SignUpPage";

import PoolManager from "./components/PoolManager/PoolManager";
import PoolManagerResidents from "./components/PoolManager/PoolManagerResidents";
import PoolManagerVisitors from "./components/PoolManager/PoolManagerVisitors";
import PoolManagerSelectChat from "./components/PoolManager/PoolManagerSelectChat";
import PoolManagerChat from "./components/PoolManager/PoolManagerChat";
import PoolReport from "./components/PoolManager/PoolReport";

import GardenManager from "./components/GardenManager/GardenManager";
import GardenManagerResidents from "./components/GardenManager/GardenManagerResidents";
import GardenManagerVisitors from "./components/GardenManager/GardenManagerVisitors";
import GardenManagerSelectChat from "./components/GardenManager/GardenManagerSelectChat";
import GardenManagerChat from "./components/GardenManager/GardenManagerChat";
import GardenReport from "./components/GardenManager/GardenReport";

import VisitorMaps from "./components/Visitor/VisitorMaps";
import VisitorChat from "./components/Visitor/VisitorChat";
import VisitorDashBoard from "./components/Visitor/VisitorDashBoard";
import VisitorChatSelectPerson from "./components/Visitor/VisitorChatSelectPerson";
import VisitorVehicleAdd from "./components/Visitor/VisitorVehicleRegistration";

import SecurityManager from "./components/SecurityManager/SecurityManager";
import VisitorManager from "./components/VisitorManagement/VisitorManager";
import BuildingManager from "./components/BuildingManagement/BuildingManager";

import UserDashBoard from "./components/UserDashBoard/UserDashBoard";
import UserDashBoardActivityPage from "./components/UserDashBoard/UserDashBoardActivityPage";
import UserDashBoardSelectChat from "./components/UserDashBoard/UserDashBoardSelectChat";
import UserDashboardVehicleAdd from "./components/UserDashBoard/UserDashboardVehicleAdd";
import UserDashBoardChat from "./components/UserDashBoard/UserDashBoardChat";
import UserDashboardVehicleView from "./components/UserDashBoard/UserDashboardVehicleView";

import BuildingManager_garden from "./components/BuldingMnaager/BuildingManager_garden";
import BuildingManager_pool from "./components/BuldingMnaager/BuildingManager_pool";
import BuildingManager_security from "./components/BuldingMnaager/BuildingManager_security";
import BuildingManagerDashBoard from "./components/BuldingMnaager/BuildingManagerDashBoard";
import BuildingManager_report from "./components/BuldingMnaager/BuildingManager_report";
import BuildingManagerVisitors from "./components/BuldingMnaager/BuildingManagerVisitors";
import BuildingManagerResident from "./components/BuldingMnaager/BuildingManagerResident";
import BuildingManagerSelectChat from "./components/BuldingMnaager/BuildingManagerSelectChat";
import BuildingManagerChat from "./components/BuldingMnaager/BuildingManagerChat";
import PageNotFound from "./PageNotFound";
import ProtectedRoute from "./ProtectedRoute";
import Chat from "./components/Chat/Chat";
import Report from "./components/report/Report";
import UserProfile from "./components/UserDashBoard/UserProfile";

function App() {
  return (
    <div className={styles.container}>
      <Routes>
        <Route path="/" element={<HomePage />} exact />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/SignUp" element={<SignUpPage />} />

        <Route path="/PoolManager" element={<PoolManager />} />
        <Route
          path="/PoolManagerResidents"
          element={<PoolManagerResidents />}
        />
        <Route path="/PoolManagerVisitors" element={<PoolManagerVisitors />} />
        <Route path="/PoolReport" element={<PoolReport />} />
        <Route
          path="/PoolManagerSelectChat"
          element={<Chat/>}
        />
        <Route path="/PoolManagerChat" element={<Chat/>} />

        <Route path="/GardenManager" element={<GardenManager />} />
        <Route
          path="/GardenManagerResidents"
          element={<GardenManagerResidents />}
        />
        <Route
          path="/GardenManagerVisitors"
          element={<GardenManagerVisitors />}
        />
        <Route path="/GardenReport" element={<GardenReport />} />
        <Route
          path="/GardenManagerSelectChat"
          element={<Chat />}
        />
        <Route path="/GardenManagerChat" element={<Chat />} />

        <Route path="/VisitorDashBoard" element={<VisitorDashBoard />} />
        <Route path="/VisitorMap" element={<VisitorMaps />} />
        <Route path="/VisitorChat" element={<Chat />} />
        <Route
          path="/VisitorSelectPerson"
          element={<Chat />}
        />
        <Route path="/VehicleRegistration" element={<VisitorVehicleAdd />} />

        <Route path="/UserDashboard" element={<UserDashBoard />} />
        <Route
          path="/UserDashboardActivity"
          element={<UserDashBoardActivityPage />}
        />
        <Route
          path="/UserDashboardVehicleAdd"
          element={<UserDashboardVehicleAdd />}
        />
        <Route
          path="/UserDashboardVehicleView"
          element={<UserDashboardVehicleView />}
        />
        <Route
          path="/UserDashboardSelectChat"
          element={<Chat />}
        />
        <Route path="/UserDashboardChat" element={<Chat />} />

        <Route
          path="/BuildingManagerDashBoard"
          element={<BuildingManagerDashBoard />}
        />
        <Route
          path="/BuildingManager-pool"
          element={<BuildingManager_pool />}
        />
        <Route
          path="/BuildingManager-security"
          element={<BuildingManager_security />}
        />
        <Route
          path="/BuildingManager-garden"
          element={<BuildingManager_garden />}
        />
        <Route
          path="/BuildingManager-report"
          element={<BuildingManager_report />}
        />
        <Route
          path="/BuildingManager-visitor"
          element={<BuildingManagerVisitors />}
        />
        <Route
          path="/BuildingManager-resident"
          element={<BuildingManagerResident />}
        />
        <Route
          path="/BuildingManagerSelectChat"
          element={<BuildingManagerSelectChat />}
        />
        <Route path="/BuildingManagerChat" element={<BuildingManagerChat />} />
        <Route path="/report" element={<Report/>} />
        <Route path="/UserProfile" element={<UserProfile/>} />

        {/* <Link to="/#">chat</Link> */}
        <Route element={<ProtectedRoute/>}>
          <Route path="/security-manager/*" element={<SecurityManager />} />
          <Route path="/visitor-manager/*" element={<VisitorManager />} />
          <Route path="/building-manager/*" element={<BuildingManager />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
