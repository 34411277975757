import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/layout/Button";
import FormInput from "../components/layout/FormInput";
import MainNavigation from "../components/NavigationBar/MainNavigation";
import stylesLogin from "../css/LoginPage.module.css";
import { validateUser } from "../apis/apiClient";

function LoginPage() {
  const nav = useNavigate();
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const inputs = [
    {
      id: 1,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "It should be a valid email address!",
      label: "Email",
      required: true,
    },
    {
      id: 2,
      name: "password",
      type: "password",
      placeholder: "Password",
      errorMessage:
        "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!",
      label: "Password",
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
    },
  ];

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  

  const handleSubmit = (e) => {
    e.preventDefault();
    let request = {
      email: values["email"],
      password: values["password"],
    };
    validateUser(request)
      .then((response) => {
        if (!response || !response.data) {
          return;
        }
        const user = response.data[0];
        localStorage.setItem("userId", user["email"]);
        let userName = user["first_name"] ? user["first_name"] +" "+ user["last_name"] : user["last_name"];
        localStorage.setItem("user", JSON.stringify({name:userName}));
        const role = user["role"];
        if (role == "securitymanager") {
          localStorage.setItem("userId", user["email"]);
          nav("/security-manager/dashboard");
        } else if (role == "visitor") {
          localStorage.setItem("visitor_id", user["email"]);
          nav("/visitor-manager/profile");
        } else if (role == "buildingmanager") {
          localStorage.setItem("userId", user["email"]);
          nav("/building-manager/dashboard");
        } else if (role == "poolmanager") {
          localStorage.setItem("userId", user["email"]);
          nav("/PoolManager");
        } else if (role == "gardenmanager") {
          localStorage.setItem("userId", user["email"]);
          nav("/GardenManager");
        } else if (role == "resident") {
          console.log(user);
          localStorage.setItem("userId", user["email"]);
          localStorage.setItem("r_id",user["resident_id"]);
          nav("/UserDashboard");
        }
      })
      .catch((err) => {
        console.error(err);
        alert(err.response.data.errorMessage);
      });
    {
      /*
    if(email==="PoolManager@gmail.com" && pswd==="PoolManager"){
      nav("/PoolManager");
    }
    else if(email==="BuildingManager@gmail.com" && pswd==="BuildingManager"){
      nav("/BuildingManagerDashBoard");
    }
    else if(email==="GardenManager@gmail.com" && pswd==="GardenManager"){
      nav("/GardenManager");
    }
    else if(email==="Visitor@gmail.com" && pswd==="Visitor"){
      localStorage.setItem("visitor_id", 1);
      nav("/visitor-manager/profile");
    }
    else if(email==="User@gmail.com" && pswd==="User"){
      nav("/UserDashboard");
    }
    else if(email==="SecurityManager@gmail.com" && pswd==="SecurityManager"){
      localStorage.setItem("userId", "amit10@gmail.com");
      nav("/security-manager/dashboard");
    }
*/
    }
  };

  return (
    <div>
      <MainNavigation />
      <div className={stylesLogin.pjwrapper}>
        <div className={stylesLogin.login}>
          <h2>Login</h2>
          <form>
            {inputs.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}
            <Button name="btnLogin" value="Login" handleClick={handleSubmit} />
          </form>
          <div className={stylesLogin.lgnlink}>
            <Link to="/ForgotPassword">Forgot Password</Link>
            <p>
              Don't have an account ? <br />
              <Link to="/SignUp">Sign up</Link> here.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
