import styles from "./Modal.module.css";

function Modal(props) {
  return (
    <div className={styles.pjwrapper}>
      <div className={styles.modal}>
        <div className={styles.modalContent}>{props.children}</div>
      </div>
    </div>
  );
}

export default Modal;
