import React from 'react'
import { Link } from "react-router-dom";
import styles from "../../css/MainNavigation.module.css";


export default function VisitorNavigationBar() {
  return (
    <div className="container">
    <header className={styles.mainNav}>
      <nav>
        <ul>
        <li>
            <Link to="/">Logout</Link>
          </li>
          <li>
            <Link to="/VisitorDashBoard">DashBoard</Link>
          </li>
          <li>
            <Link to="/VisitorMap">Map</Link>
          </li>
          <li>
            <Link to="/VisitorSelectPerson">Chat</Link>
          </li>
          <li>
            <Link to="/VehicleRegistration">VehicleRegistration</Link>
          </li>
        </ul>
      </nav>
    </header>
  </div>
)
}

