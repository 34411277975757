import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://terrazasapp.dxj0015.uta.cloud/terrazas-de-gucaco-backend/security-management/api/",
  //baseURL: "http://localhost/terrazas-de-gucaco-backend/security-management/api/",
});

export const getAllEvents = async () => {
  const response = await apiClient.get("/events.php");
  if (response.data.status_code == 200) {
    return response.data;
  }
  return [];
};

export const getVisitorEvents = async (visitor_id) => {
  const response = await apiClient.get(`/events.php?visitor_id=${visitor_id}`);
  if (response.data.status_code == 200) {
    return response.data;
  }
  return [];
};


export const registerEvent = async (request) => {
  const response = await apiClient.post("/events.php",request);
  if (response.data.status_code == 200) {
    return response.data;
  }
  return [];
};

export const cancelEvent = async (event_id,visitor_id) => {
  const response = await apiClient.delete(`/events.php?event_id=${event_id}&visitor_id=${visitor_id}`);
  if (response.data.status_code == 200) {
    return response.data;
  }
  return [];
}


export const validateUser = async (request) => {
  const response = await apiClient.post("/validate_login.php",request);
  if (response.data.status_code == 200) {
    return response.data;
}else if(response.data.status_code == 401){
    alert(response.data.errorMessage);
    return null;
}
  return [];
};


export const createUser = async (request) => {
    const response = await apiClient.post("/createUser.php",request);
      return response.data;
  };

  export const forgotPassword = async (request) => {
    const response = await apiClient.post("/forgotPassword.php",request);
      return response.data;
  };

  export const contactUs = async (request) => {
    const response = await apiClient.post("/contactUs.php",request);
      return response.data;
  };

  export const report = async () => {
    const response = await apiClient.get("/report.php");
      return response.data;
  };

  
export const getAllManagers= async () => {
  const response = await apiClient.get("/manager.php");
  return response.data;
};

export const createManager= async (request) => {
  const response = await apiClient.post("/manager.php", request);
  return response.data;
};

export const updateManager= async (request) => {
  const response = await apiClient.patch("/manager.php", request);
  return response.data;
};

export const deleteManager= async (email) => {
  const response = await apiClient.delete(`/manager.php?email=${email}`);
  return response.data;
};
  
export const getAllResidents = async () => {
  const response = await apiClient.get("/resident.php");
  if (response.data.status_code == 200) {
    return response.data;
  }
  return [];
};

export const updateResident = async (request) => {
  const response = await apiClient.patch("/resident.php",request);
  if (response.data.status_code == 200) {
    return response.data;
  }
  return [];
};

export const createResident= async (request) => {
  const response = await apiClient.post("/resident.php", request);
  return response.data;
};

export const deleteResident= async (email) => {
  const response = await apiClient.delete(`/resident.php?email=${email}`);
  return response.data;
};

export const getAllSecurity = async () => {
  const response = await apiClient.get("/security.php");
  if (response.data.status_code == 200) {
    return response.data;
  }
  return [];
};

export const createSecurity = async (request) => {
  const response = await apiClient.post("/security.php",request);
  if (response.data.status_code == 200) {
    return response.data;
  }
  return [];
};

export const updateSecurity = async (request) => {
  const response = await apiClient.patch("/security.php",request);
  if (response.data.status_code == 200) {
    return response.data;
  }
  return [];
};
export const deletSecurity = async (securityId) => {
  const userId = localStorage.getItem("userId");
  const response = await apiClient.delete(`/security.php?security_id=${securityId}&userId=${userId}`);
  if (response.data.status_code == 200) {
    return response.data;
  }
  return [];
};

export const getAllShifts = async () => {
  const response = await apiClient.get("/timings.php");
  if (response.data.status_code == 200) {
    return response.data;
  }
  return [];
};

export const createShift = async (request) => {
  const response = await apiClient.post("/timings.php",request);
  if (response.data.status_code == 200) {
    return response.data;
  }
  return [];
};

export const updateShift = async (request) => {
  const response = await apiClient.patch("/timings.php",request);
  if (response.data.status_code == 200) {
    return response.data;
  }
  return [];
};
export const deletShift = async (shiftId) => {
  const userId = localStorage.getItem("userId");
  const response = await apiClient.delete(`/timings.php?shift_id=${shiftId}&userId=${userId}`);
  if (response.data.status_code == 200) {
    return response.data;
  }
  return [];
};


export const getAllVisitors = async () => {
  const response = await apiClient.get("/visitor.php");
  if (response.data.status_code == 200) {
    return response.data;
  }
  return [];
};

export const getVisitorDetails = async (visitor_id) => {
  const response = await apiClient.get(`/visitor.php?visitor_id=${visitor_id}`);
  if (response.data.status_code == 200) {
    return response.data;
  }
  return [];
};

export const updateVisitor = async (request) => {
  const response = await apiClient.patch("/visitor.php",request);
  if (response.data.status_code == 200) {
    return response.data;
  }
  return [];
};

export const createVisitor= async (request) => {
  const response = await apiClient.post("/visitor.php", request);
  return response.data;
};


export const deleteVisitor= async (email) => {
  const response = await apiClient.delete(`/visitor.php?email=${email}`);
  return response.data;
};



