import React from "react";
import { useState, useEffect } from "react";
import ReadOnlyTable from "./ReadOnlyTable";
import Loader from "../spinning-loader/loader";
import {
  getVisitorEvents,
  registerEvent,
  cancelEvent,
} from "../../apis/apiClient";

const VisitorTimings = () => {
  const [data, setData] = useState([]);
  const visitor_id = localStorage.getItem("v_id");
  useEffect(() => {
    getVisitorEvents(visitor_id)
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleEventRegistration = (row) => {
    let request = {
      event_id: row.event_id,
      visitor_id: visitor_id,
    };
    registerEvent(request)
      .then((response) => {
        const temp = [...response.data];
        setData(temp);
        alert(response.successMessage);
      })
      .catch((err) => {
        console.error(err);
        alert(err.response.data.errorMessage);
      });
  };

  const handeEventCancellation = (row) => {
    cancelEvent(row.event_id, visitor_id)
      .then((response) => {
        if (response.data[0] != -1) {
          const temp = [...data];
          let index = temp.findIndex(
            (event) => event.event_id == response.data[0]
          );
          if (index != -1) {
            temp[index].visitor_id = null;
            setData(temp);
          }
        }
        alert(response.successMessage);
      })
      .catch((err) => {
        alert(err.response.errorMessage);
        console.log(err.response.data.errorMessage);
        console.error(err);
      });
  };

  if (!data.length) {
    return <Loader />;
  }

  return (
    <div>
      <ReadOnlyTable
        data={data}
        handleEventRegistration={handleEventRegistration}
        handleEventCancellation={handeEventCancellation}
        view="visitor-timings"
      />
    </div>
  );
};
export default VisitorTimings;
