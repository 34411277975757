import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { report } from '../../apis/apiClient';
import Loader from '../spinning-loader/loader';

const Report = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data from the database
    report()
      .then((response) =>{
        let reportData = response.data
        setData(reportData);
      })
      .catch(error => console.error(error));
  }, []);

  const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Count', key: 'count' },
  ];

  if (!data.length){
    return <Loader/>
   } 
  return (
    <CSVLink data={data} headers={headers} filename={'report.csv'}>
      Download Report
    </CSVLink>
  );
};

export default Report;
