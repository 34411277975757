import { Route, Routes } from "react-router";
import Navigation from "./Navigation";
import styles from './BuildingManager.module.css'
import DashBoard from "./DashBoard";
import SecurityDetails from "../SecurityManager/SecurityDetails";
import Managers from "./Managers";
import Residents from "./Residents";
import Visitor from "./Visitors";
import Visitors from "./Visitors";
import Report from "../report/Report";
import Chat from "../Chat/Chat";

const BuildingManager = ()=>{
    return (
        <div className={styles.container}>
          <Navigation />    
          <Routes>
            <Route path="dashboard" element={<DashBoard />} />
            <Route path="security-management" element={<SecurityDetails />} />
            <Route path="managers" element={<Managers/>} />
            <Route path="residents" element={<Residents/>} />
            <Route path="visitors" element={<Visitors/>} />
            <Route path="report" element={<Report/>} />
            <Route path="chat" element={<Chat/>} />
          </Routes>
        </div>
      );
}

export default BuildingManager;