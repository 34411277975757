import React from "react";
import DataTable from "react-data-table-component";
import btnStyles from "./ReadOnlyTable.module.css";

const ReadOnlyTable = (props) => {
  const customStyles = {
    table: {
      style: {
        border: "1px solid #e0e0e0",
      },
    },
    header: {
      style: {
        backgroundColor: "#f8f8f8",
        borderBottom: "1px solid #e0e0e0",
        color: "#333",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
        color: "#444",
      },
    },
    pagination: {
      style: {
        borderTop: "1px solid #e0e0e0",
      },
    },
  };


 let columns = [];

  if (props.view === "events") {
     columns = [
      {
        name: "Id",
        selector: (row) => row.event_id,
      },
      {
        name: "Name",
        selector: (row) => row.name,
      },
      {
        name: "Category",
        selector: (row) => row.category,
      },
      {
        name: "",
        cell: (row) => (
          <div>
            <button
              className={
                row.visitor_id == null
                  ? btnStyles.btnPrimary
                  : btnStyles.disabledBtn
              }
              onClick={(event) => handleEventRegistration(event, row)}
            >
              Register
            </button>
  
            <button
              className={
                row.visitor_id != null
                  ? btnStyles.btnPrimary
                  : btnStyles.disabledBtn
              }
              onClick={(event) => handleEventCancellation(event, row)}
            >
              Cancel
            </button>
          </div>
        ),
      },
    ];
  }else if(props.view === 'visitor-timings'){
    columns = [
      {
        name: "Id",
        selector: (row) => row.event_id,
      },
      {
        name: "Name",
        selector: (row) => row.name,
      },
      {
        name: "Start Time",
        selector: (row) => row.starts_at,
      },
      {
        name: "End Time",
        selector: (row) => row.ends_at,
      }
    ]
  }
  

  const handleEventRegistration = (event, row) => {
    event.preventDefault();
    props.handleEventRegistration(row);
  };

  const handleEventCancellation = (event, row) => {
    event.preventDefault();
    props.handleEventCancellation(row);
  };

    return (
      <div>
        <DataTable customStyles={customStyles} columns={columns} data={props.data} />
      </div>
    );
};

export default ReadOnlyTable;
